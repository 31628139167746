var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "shelve_goods_management" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "self-adapting-condition" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开票类型：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        multiple: false,
                        "collapse-tags": "",
                        placeholder: "请选择",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.businessType,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "businessType", $$v)
                        },
                        expression: "msForm.businessType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "用友开票", value: 1 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "应用场景：" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        multiple: false,
                        "collapse-tags": "",
                        placeholder: "请选择",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.appScenario,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "appScenario", $$v)
                        },
                        expression: "msForm.appScenario"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "通用开票", value: 0 }
                      }),
                      _c("el-option", {
                        attrs: { label: "个性开票", value: 1 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.ifEnableArr,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "ifEnableArr", $$v)
                        },
                        expression: "msForm.ifEnableArr"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: true } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: false }
                        },
                        [_vm._v("停用")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticStyle: { clear: "both" } })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "independent-button-row" },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function($event) {
                  return _vm.queryShelveGoodsList(1)
                }
              }
            },
            [_vm._v("查询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-icontext-28",
              attrs: { icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  return _vm.openDialog()
                }
              }
            },
            [_vm._v(" 添加 ")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          attrs: {
            "row-key": "uuid",
            data: _vm.msForm.tableList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "appScenario",
              label: "应用场景",
              width: "100",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          { 0: "通用开票", 1: "个性开票" }[
                            scope.row.appScenario
                          ] || "-"
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "businessType",
              label: "开票类型",
              width: "100",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s({ 1: "用友开票" }[scope.row.businessType] || "-")
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "invoiceDescribe",
              label: "配置名称",
              "min-width": "300",
              "header-align": "center",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.invoiceDescribe || "-"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "useUnitNum",
              label: "使用单位数",
              width: "120",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.useUnitNum || "-"))])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ifEnable",
              label: "状态",
              width: "110",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable
                      ? _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-normal" },
                          [_vm._v("启用")]
                        )
                      : _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-finish" },
                          [_vm._v("停用")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "300",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.openDialog(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(scope.row)
                              }
                            }
                          },
                          [_vm._v("查看")]
                        ),
                    scope.row.ifEnable == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.changeState(scope.row.uuid, 1)
                              }
                            }
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e(),
                    scope.row.ifEnable == 1
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 el-button--danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.changeState(scope.row.uuid, 0)
                              }
                            }
                          },
                          [_vm._v("停用")]
                        )
                      : _vm._e(),
                    scope.row.ifEnable == 0
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 el-button--danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteGoods(scope.row.uuid)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("base-pagination", {
        attrs: {
          parentPage: _vm.msForm.currentPage,
          pageSize: _vm.msForm.pageSize,
          total: _vm.msForm.total
        },
        on: { currentChange: _vm.queryShelveGoodsList }
      }),
      _c("InvoiceCreateDialog", {
        ref: "invoiceCreateDialog",
        on: { confirm: _vm.add }
      }),
      _c("InvoiceDetailDialog", {
        ref: "invoiceDetailDialog",
        on: { loading: _vm.loading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }