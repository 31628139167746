var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "zwx-dialog",
      attrs: {
        title: "详情",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              key: "msForm",
              ref: "msForm",
              staticClass: "ms-form",
              attrs: {
                model: _vm.msForm,
                "label-position": "right",
                "label-width": "150px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用场景：", prop: "appScenario" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        { 0: "通用商户", 1: "个性商户" }[
                          _vm.msForm.appScenario
                        ] || "-"
                      )
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票类型：", prop: "businessType" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s({ 1: "用友开票" }[_vm.msForm.businessType] || "-")
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配置名称：", prop: "invoiceDescribe" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.msForm.invoiceDescribe || "-"))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "环境地址：", prop: "invoiceEnv" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.invoiceEnv || "-"))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "开票申请API地址：", prop: "invoiceApiUrl" }
                },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.invoiceApiUrl || "-"))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APPKEY：", prop: "invoiceAppKey" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.invoiceAppKey || "-"))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP密钥：", prop: "invoiceAppSecret" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.msForm.invoiceAppSecret || "-"))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票保存地址：", prop: "invoicePdfPath" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.invoicePdfPath || "-"))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "纳税人识别号：",
                    prop: "invoiceSellerCreditCode"
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.msForm.invoiceSellerCreditCode || "-"))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品税率：", prop: "invoiceTaxRate" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.invoiceTaxRate || "-"))])]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "是否无需审核：", prop: "invoiceAutoAudit" }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.msForm.invoiceAutoAudit ? "直接开票" : "需要审核"
                      )
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "电子税务局用户名：",
                    prop: "invoiceAllelcUsername"
                  }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.msForm.invoiceAllelcUsername || "-"))
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }