<template>
    <div>
      <base-dialog ref="postDialog" :title="'添加岗位环节'" @determine="addPostForm" class="tip-dialog" @cancel="closePost">
        <el-form class="zwx-form ss" ref="postForm" style="padding-left:30px;padding-top:20px;" :model="postForm" label-position="right" :rules="postRules">
          <el-form-item label-width="140px" label="部门车间：" prop="workplaceName" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="postForm.workplaceName" placeholder="请输入" clearable maxlength="50" />
          </el-form-item>
          <el-form-item label-width="140px" label="检测岗位：" prop="jobName" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="postForm.jobName" placeholder="请输入" maxlength="50" clearable />
          </el-form-item>
          <el-form-item label-width="142px" label="作业人数：" prop="staffNumber" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="postForm.staffNumber" @input="inputStaffNumber" placeholder="请输入" clearable maxlength="5" />
          </el-form-item>
          <el-form-item label-width="142px" label="每班接触时长：" prop="exposureHd" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="postForm.exposureHd" @input="inputExposureHd" placeholder="请输入" clearable />
            <div class="unit">h/d</div>
          </el-form-item>
          <el-form-item label-width="142px" label="每周接触天数：" prop="exposureDw" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="postForm.exposureDw" @input="inputExposureDw" placeholder="请输入" clearable />
            <div class="unit">d/w</div>
          </el-form-item>
          <!-- <el-form-item label-width="140px" label="检测项目：" prop="detectionItem" style="padding-bottom: 5px;">
            <el-checkbox-group class="zwx-checkbox-group" v-model="postForm.detectionItem">
              <el-checkbox v-for="(item, index) in detectionItem" class="zwx-checkbox" :label="item.itemCode" :key="index">{{ item.itemName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item> -->
        </el-form>
      </base-dialog>
    </div>
  </template>
  <script>
  export default {
    name: 'postDialog',
    props: {
      detectionItem: { type: Array, default: () => [] },
    },
    data() {
      return {
        postForm: { detectionItem: [], placeData: [] },
        postRules: {
          /*placeData: [{ required: true, validator: this.placeDataListRules, trigger: ['change', 'blur'] }],*/
          detectionItem: [{ required: true, validator: this.detectionItemRules, trigger: ['change', 'blur'] }],
          staffNumber: { required: true, message: '必填', trigger: 'blur' },
          exposureHd: { required: false, validator: this.exposureHdRules, trigger: 'blur' },
          exposureDw: { required: false, validator: this.exposureDwRules, trigger: 'blur' },
          workplaceName: { required: true, message: '必填', trigger: 'blur' },
          jobName: { required: true, message: '必填', trigger: 'blur' },
        },
        ifPostEdit: false,
        // detectionItem: [], //存放检测项目
      }
    },
    methods: {
      show(msForm) {
        this.postForm = { ...msForm }
        this.$refs['postDialog'].show(true)
        this.ifPostEdit = this.postForm.ifPostEdit
        this.$nextTick(() => {
          this.$refs.postForm.clearValidate('detectionItem')
        })
      },
      addPostForm() {
        this.$refs.postDialog.show(false)
        this.$refs['postForm'].validate(valid => {
          if (valid) {
            this.$emit('addPostForm', this.postForm)
          }
        })
      },
      closePost() {
        this.$refs['postDialog'].show(false)
        this.$nextTick(() => {
          // this.postForm = {}
          if (this.$refs['postForm']) {
            this.$refs['postForm'].resetFields()
          }
          // this.$emit('input', {})
        })
      },
      detectionItemRules(rule, value, callback) {
        if (!this.postForm.detectionItem || this.postForm.detectionItem.length == 0) {
          return callback(new Error('请选择一个检测项目'))
        }
        return callback()
      },
      exposureHdRules(rule, value, callback) {
        if (Number(value) < 0 || Number(value) >= 12) {
          return callback(new Error('请输入0~12(不包含12)'))
        }
        return callback()
      },
      exposureDwRules(rule, value, callback) {
        if (Number(value) < 0 || Number(value) >= 7) {
          return callback(new Error('请输入0~7(不包含7)'))
        }
        return callback()
      },
      inputStaffNumber(value) {
        this.postForm.staffNumber = value.replace(/[^\d]/g, '')
      },
      inputExposureHd(value) {
        this.postForm.exposureHd = this.oninput(value, 1)
      },
      inputExposureDw(value) {
        this.postForm.exposureDw = this.oninput(value, 1)
      },
      oninput(num, limit) {
        var str = num
        var len1 = str.substr(0, 1)
        var len2 = str.substr(1, 1)
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 == 0 && len2 != '.') {
          str = str.substr(1, 1)
        }
        //第一位不能是.
        if (len1 == '.') {
          str = ''
        }
        //限制只能输入一个小数点
        if (str.indexOf('.') != -1) {
          var str_ = str.substr(str.indexOf('.') + 1)
          if (str_.indexOf('.') != -1) {
            str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
          }
        }
        //正则替换
        str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
        if (limit / 1 === 1) {
          str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, '$1') // 小数点后只能输 1 位
        } else {
          str = str.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1') // 小数点后只能输 2 位
        }
        return str
      },
    },
  }
  </script>
  <style lang="less" scoped>
  .ss {
    /deep/ .ms-input {
      width: 270px !important;
    }
  }
  .unit {
    display: inline-flex;
    width: 34px;
    height: 32px;
    background: #eaeff5;
    border: 1px solid #a8bbcf;
    border-radius: 0px 2px 2px 0px;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: -1px;
    font-size: 14px;
    color: #1f2d3d;
    line-height: 14px;
  }
  </style>
  