<template>
    <div class="harm">
      <base-dialog ref="harmDialog" :title="'选择危害因素'" @determine="addHarmList" class="tip-dialog" @cancel="harmClose">
        <div style="display:flex;">
          <!-- 职业病选择左侧部分 -->
          <div class="hazardFactor-list2">
            <div class="hazardFactor-search">
              <el-input placeholder="" style="width: 270px !important; margin-right: 10px !important" class="zwx-input" v-model="searchInput3">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <div style="height: 440px">
              <zwx-scrollbar-y style="height: 100%">
                <el-tree class="filter-tree" empty-text="暂无数据" :data="gbzOhsSuspectedCodeNoTreeList" :props="defaultProps3" show-checkbox @check-change="handleCheckChange3" accordion node-key="codeNo" ref="tree3" :filter-node-method="filterNodeMethod3" :expand-on-click-node="true" :default-expanded-keys="checkRidArr3" :check-strictly="isCheckStrictly3">
                  <div class="custom-tree-node" slot-scope="{ node, data }" style="display: flex; justify-content: space-between; width: 100%">
                    <div style="display: flex">
                      <div class="name-hidden">{{ node.label }}</div>
                    </div>
                  </div>
                </el-tree>
              </zwx-scrollbar-y>
            </div>
          </div>
          <!-- 职业病选择右侧部分 -->
          <div class="equipment-detail2">
            <div style="padding: 10px 0px 10px 12px;border-bottom:1px solid #CDCDCC ">已选危害因素({{ showOhsSuspectedCodeNoList.length }})</div>
            <!-- 列表展示部分 -->
            <el-table class="zwx-table harmTable" height="461" width="100%" ref="peResultDetailListTable" row-key="itemCode" border stripe :data="showOhsSuspectedCodeNoList" :show-header="false">
              <el-table-column width="330" prop="hazardsName" label="职业病名称" header-align="center" align="left" />
              <el-table-column label="操作" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-button class="ms-button ms-button-text-26" type="text" icon="el-icon-delete" @click="delCheckResultDetail3(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </base-dialog>
      <div style="position:absolute;opacity:0;display: none;">
        <hazard-factor-multiple-edit @callback="callback" ref="peHazardFactorMultipleEditRef" :key="hazardFactorKey" :innerWidth="610" :options="gbzOhsSuspectedCodeNoTreeList" dataPyxNameFiled="hazardsName" dataNameFiled="hazardsName" showNameFiled="hazardName" dataCodeFiled="codeNo" dataUpCodeFiled="upcodeNo" @change="callback"></hazard-factor-multiple-edit>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'msInput',
    prop: {
      value: { type: String, default: '' },
      employerUuid: { type: String, default: '' },
    },
    data() {
      return {
        api: this.$store.state.api,
        valueData: '',
        showOhsSuspectedCodeNoList: [],
        oldShowOhsSuspectedCodeNoList: [],
        searchInput3: '',
        hazardFactorKey: '323',
        defaultProps3: {
          children: 'children',
          label: 'hazardsName',
          code: 'codeNo',
        },
        isCheckStrictly3: false,
        gbzOhsSuspectedCodeNoTreeList: [],
        checkRidArr3: [], //编辑默认展开的节点的 key 的数组
      }
    },
    watch: {
      searchInput3() {
        this.search3(this.searchInput3)
      },
    },
    created() {
      this.getHazardsFactorStandardShow()
    },
    methods: {
      addHarmList() {
        this.$emit('addHarmList', [...this.showOhsSuspectedCodeNoList])
        this.harmClose()
      },
      harmClose() {
        this.showOhsSuspectedCodeNoList = []
        this.oldShowOhsSuspectedCodeNoList = []
        this.searchInput3 = ''
        this.$refs['harmDialog'].show(false)
      },
      show() {
        this.$refs['harmDialog'].show(true)
      },
      handleCheckChange3(data, checked, indeterminate) {
        let showOhsSuspectedCodeNoList = this.$refs.tree3 && this.$refs.tree3.getCheckedNodes(true)
        if (this.$zwxBase.verifyIsNotBlank(this.searchInput3)) {
          let resultList = []
          showOhsSuspectedCodeNoList.forEach(item => {
            if (item.label.indexOf(this.searchInput3) !== -1) {
              resultList.push(item)
            }
          })
          if (checked) {
            resultList.forEach(item => {
              let flag = this.showOhsSuspectedCodeNoList.some(e => e.codeNo == item.codeNo)
              if (!flag) {
                this.showOhsSuspectedCodeNoList.push(item)
              }
            })
            this.oldShowOhsSuspectedCodeNoList = this.showOhsSuspectedCodeNoList
          } else {
            this.oldShowOhsSuspectedCodeNoList.forEach(item => {
              let flag = resultList.some(e => e.codeNo == item.codeNo)
              if (!flag) {
                let index = this.showOhsSuspectedCodeNoList.indexOf(item)
                if (index !== -1) {
                  this.showOhsSuspectedCodeNoList.splice(index, 1)
                  this.oldShowOhsSuspectedCodeNoList.splice(index, 1)
                }
              }
            })
          }
        } else {
          this.showOhsSuspectedCodeNoList = showOhsSuspectedCodeNoList
          this.oldShowOhsSuspectedCodeNoList = showOhsSuspectedCodeNoList
        }
      },
      /**
       * 获取危害因素数据源
       * */
      getHazardsFactorStandardShow() {
        this.$system.get(
          this.api + '/oh/repository/getHazardsStandardDataIncludeLimit-1',
          { employerUuid: this.employerUuid },
          true,
          true,
          data => {
            if (data.type == '00') {
              let list = data.dataList || []
              this.gbzOhsSuspectedCodeNoTreeList = list
              /*.map(item => {
              if ((!item.limitExpList || item.limitExpList.length == 0) && item.levelCodeNo.split('.').length > 1) {
                item.disabled = true
              }
              return item
            })
            this.gbzOhsSuspectedCodeNoTreeList.forEach(item => {
              this.harmMap[item.codeNo] = item
            })*/
              this.hazardFactorKey = new Date().getTime()
            } else if (data.type === '99') {
              this.$router.push({ name: 'MsError', params: { mess: data.mess } })
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      },
      delCheckResultDetail3(index) {
        let node = this.showOhsSuspectedCodeNoList[index]
        this.$refs.tree3.setChecked(node.codeNo, false, true)
        this.showOhsSuspectedCodeNoList.splice(index, 1)
      },
  
      filterNodeMethod3(query, data) {
        if (query === undefined || query === null || query === '') return true
        return data.label.indexOf(query) !== -1
      },
      search3(searchInput) {
        this.searchInput3 = searchInput
        this.$refs['tree3'] && this.$refs['tree3'].filter(this.searchInput3)
      },
      callback(val) {
        this.gbzOhsSuspectedCodeNoTreeList = val
      },
    },
  }
  </script>
  <style lang="less" scoped>
  .harmTable {
    /deep/ .cell {
      padding: 0px 14px !important;
    }
  }
  .harm {
    /deep/ .el-dialog {
      width: 43% !important;
      min-width: 500px !important;
    }
  }
  .hazardFactor-list2 {
    //左侧部分
    width: 300px;
    height: 500px;
    border-right: 1px solid #e6e6e6;
    .hazardFactor-search {
      padding: 12px 0px 12px 12px;
      border-bottom: 1px solid #e6e6e6;
    }
    .name-hidden {
      white-space: nowrap;
      width: 200px;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
  .equipment-detail2 {
    //右侧部分
    width: calc(100% - 277px);
    height: 500px;
    // flex:1;
  
    .header-title {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #1f2d3d;
      line-height: 14px;
  
      .ms-button {
        margin-left: 24px;
      }
    }
  }
  </style>
  