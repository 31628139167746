var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "harm" },
    [
      _c(
        "base-dialog",
        {
          ref: "harmDialog",
          staticClass: "tip-dialog",
          attrs: { title: "选择危害因素" },
          on: { determine: _vm.addHarmList, cancel: _vm.harmClose }
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("div", { staticClass: "hazardFactor-list2" }, [
              _c(
                "div",
                { staticClass: "hazardFactor-search" },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "zwx-input",
                      staticStyle: {
                        width: "270px !important",
                        "margin-right": "10px !important"
                      },
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.searchInput3,
                        callback: function($$v) {
                          _vm.searchInput3 = $$v
                        },
                        expression: "searchInput3"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-input__icon el-icon-search",
                        attrs: { slot: "suffix" },
                        slot: "suffix"
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { height: "440px" } },
                [
                  _c(
                    "zwx-scrollbar-y",
                    { staticStyle: { height: "100%" } },
                    [
                      _c("el-tree", {
                        ref: "tree3",
                        staticClass: "filter-tree",
                        attrs: {
                          "empty-text": "暂无数据",
                          data: _vm.gbzOhsSuspectedCodeNoTreeList,
                          props: _vm.defaultProps3,
                          "show-checkbox": "",
                          accordion: "",
                          "node-key": "codeNo",
                          "filter-node-method": _vm.filterNodeMethod3,
                          "expand-on-click-node": true,
                          "default-expanded-keys": _vm.checkRidArr3,
                          "check-strictly": _vm.isCheckStrictly3
                        },
                        on: { "check-change": _vm.handleCheckChange3 },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function({ node, data }) {
                              return _c(
                                "div",
                                {
                                  staticClass: "custom-tree-node",
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "name-hidden" },
                                        [_vm._v(_vm._s(node.label))]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "equipment-detail2" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      padding: "10px 0px 10px 12px",
                      "border-bottom": "1px solid #CDCDCC"
                    }
                  },
                  [
                    _vm._v(
                      "已选危害因素(" +
                        _vm._s(_vm.showOhsSuspectedCodeNoList.length) +
                        ")"
                    )
                  ]
                ),
                _c(
                  "el-table",
                  {
                    ref: "peResultDetailListTable",
                    staticClass: "zwx-table harmTable",
                    attrs: {
                      height: "461",
                      width: "100%",
                      "row-key": "itemCode",
                      border: "",
                      stripe: "",
                      data: _vm.showOhsSuspectedCodeNoList,
                      "show-header": false
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        width: "330",
                        prop: "hazardsName",
                        label: "职业病名称",
                        "header-align": "center",
                        align: "left"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "header-align": "center",
                        align: "center"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-button", {
                                staticClass: "ms-button ms-button-text-26",
                                attrs: { type: "text", icon: "el-icon-delete" },
                                on: {
                                  click: function($event) {
                                    return _vm.delCheckResultDetail3(
                                      scope.$index
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        {
          staticStyle: { position: "absolute", opacity: "0", display: "none" }
        },
        [
          _c("hazard-factor-multiple-edit", {
            key: _vm.hazardFactorKey,
            ref: "peHazardFactorMultipleEditRef",
            attrs: {
              innerWidth: 610,
              options: _vm.gbzOhsSuspectedCodeNoTreeList,
              dataPyxNameFiled: "hazardsName",
              dataNameFiled: "hazardsName",
              showNameFiled: "hazardName",
              dataCodeFiled: "codeNo",
              dataUpCodeFiled: "upcodeNo"
            },
            on: { callback: _vm.callback, change: _vm.callback }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }