<template>
  <div class="Touch">
    <base-headline title="基本信息" class="topTitle" />
    <el-form class="zwx-form edit-form" ref="addForm" key="addForm" :model="addForm" :rules="rules1" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="110px" label="单位名称：" prop="employerName">
          <unit-select class="uni-sel" :isSms4="true" ref="unitSelectRef" width="300px" v-model="addForm.employerName" placeholderText="请输入5个字符搜索" :isPost="true" :requestUrl="requestUrl" otherRequestUrl="/data/exchange/getUnitListFromEnterprise-0" :ifShowMoreBtn="true" :ifNeedMore="true" @unitNameSearch="unitNameSearch" @unitNameSearchOrDel="unitNameDel" @change="unitNameSelect" @loading="data => $emit('loading', data)" />
        </el-form-item>
        <el-form-item label-width="110px" label="社会信用代码：" prop="creditCode">
          <el-input class="zwx-input" style="width:300px !important" v-model="addForm.creditCode" placeholder="请输入社会信用代码" clearable :disabled="creditCodeDisabled" />
        </el-form-item>
      </div>

      <div class="edit-row">
        <el-form-item label-width="110px" label="行政区划：" prop="bizZone">
          <zwx-select-area ref="zoneCodeArea" class="select-component" placeholder="请选择" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" />
        </el-form-item>
        <el-form-item label-width="110px" label="检测类型：" prop="detectionType">
          <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="addForm.detectionType">
            <el-radio class="zwx-radio" :label="1">定期检测</el-radio>
            <el-radio class="zwx-radio" :label="3">监督检测</el-radio>
            <el-radio class="zwx-radio" :label="2">现状评价</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>

      <div class="edit-row">
        <el-form-item label-width="110px" label="报告日期：" prop="reportDate">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width:300px !important" v-model="addForm.reportDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="" :picker-options="$validate.noOverToday()" />
        </el-form-item>
        <el-form-item label-width="110px" label="报告编号：" prop="reportCode">
          <el-input class="zwx-input" style="width:300px !important" v-model="addForm.reportCode" placeholder="请输入报告编号" clearable maxlength="100" />
        </el-form-item>
      </div>

      <div class="edit-row">
        <!-- <el-form-item label-width="110px" label="报告附件：" prop="annexList1">
          <div style="display:flex;">
            <file-upload ref="msPositive1" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".pdf,.PDF" :size="50 * 1024 * 1024" sizeInfo="50M" :limit="5">
              <div style="text-align: left">
                <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2">上传</el-button>
              </div>
            </file-upload>
            <div style="display: inline-block;margin-left:20px;" class="show-file" v-if="addForm.annexList1.length > 0">
              <div style="display: flex;align-items: center;" v-for="(item, index) in addForm.annexList1" :key="item.fileName" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
                <div class="file-name-hidden">
                  <a style="color:#194DFF !important;margin-left:0px;cursor:pointer;" @click="openFilePreview1(addForm.annexList1, index)">
                    <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
                    <span :title="item.fileName">{{ item.fileName }}</span>
                  </a>
                </div>
                <div>
                  <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
                  <span class="check" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive1(index)"></i></span>
                </div>
              </div>
            </div>
          </div>
        </el-form-item> -->
        <attachmen-upload labelwidth="110px" folder="/workplace_detection" v-model="addForm.annexList1" label="报告附件：" accept=".pdf,.PDF" :limit="5" fileProp="annexList1" />
      </div>
      <base-headline title="主要产品">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addProduct" :disabled="addForm.ifClassifiedUnit">添加</el-button>
        <el-checkbox v-model="addForm.ifClassifiedUnit" style="margin-left: 14px">军工等涉密企业</el-checkbox>
        <div class="ms-el-message ms-el-message--warning" style="display: inline-block;margin-left: 10px;">
          <i class="el-icon-warning"></i>
          非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。
        </div>
      </base-headline>
      <!-- <div class="edit-row">
        <el-form-item label-width="150px" label="军工等涉密产品：" prop="ifClassifiedUnit">
          <el-radio-group style="min-width:260px !important;" class="zwx-radio-group" v-model="addForm.ifClassifiedUnit">
            <el-radio class="zwx-radio" :label="false">否</el-radio>
            <el-radio class="zwx-radio" :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </div> -->
      <div v-if="!addForm.ifClassifiedUnit">
        <!-- <el-button class="zwx-button zwx-button-icontext-28" style="margin-bottom:8px;" icon="el-icon-plus" @click="addProduct">添加</el-button> -->
        <!-- <el-form :model="products" :rules="productsRules" ref="productform" > -->
        <el-table class="zwx-table" style="width: 100%;" :data="addForm.products" border stripe>
          <el-table-column prop="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品名称" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.productName'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请输入产品名称', trigger: 'blur' }]">
                <el-input v-model="scope.row.productName" class="zwx-input" style="width:200px !important" placeholder="请输入" maxlength="50"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="年产量" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.annualOutput'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请输入年产量', trigger: 'blur' }]">
                <el-input v-model="scope.row.annualOutput" class="zwx-input" style="width:200px !important" oninput="value=value.replace(/[^0-9.]/g, '')" placeholder="请输入数字" maxlength="12"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="计量单位" :width="240" header-align="center" align="center">
            <template slot-scope="scope">
              <el-form-item :prop="'products.' + scope.$index + '.unit'" :rules="[{ required: !addForm.ifClassifiedUnit, message: '请选择单位', trigger: 'change' }]">
                <el-autocomplete class="zwx-input" v-model.trim="scope.row.unit" value-key="label" :fetch-suggestions="unitAutocompleteQuery" :maxlength="10" clearable></el-autocomplete>
                <!-- <el-input v-model="scope.row.annualOutput" class="zwx-input" style="width:200px !important" placeholder="请输入数字"></el-input> -->
                <!-- <el-select v-model="scope.row.unit" class="zwx-input" style="width:200px !important">
                  <el-option v-for="item in unitOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select> -->
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="removeRow(scope.$index)" type="text" style="color: red;">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-form> -->
      </div>
    </el-form>
    <base-headline title="检测信息" class="topTitle" />
    <div class="Touch-body">
      <div style="display:flex;">
        <div style="font-weight: 400;">
          <!-- <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" style="margin-left:10px;" @click="addHarm()"><span>检测危害因素</span></el-button> -->
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" style="margin-left:10px;" @click="addDepartment()">添加</el-button>
        </div>
        <div class="remind" style="margin-left: 12px;">
          <img src="@/assets/images/workplace-detection/icon_info-circle-fill.png" alt="" />
          <span>
            同一岗位或地点具有多个检测结果时，应填报最高值
          </span>
        </div>
      </div>
      <div class="department" v-for="(item, index) in deptExpList" :key="index" style="margin-top: 12px;">
        <div class="department-title">
          <i class="el-icon-caret-bottom" :style="`transform:rotate(${item.ifExpand ? -90 : 0}deg);`" @click="expand(item)"></i>
          <span>{{ item.workplaceName }}</span>
          <i class="el-icon-plus" @click="addPost(index)"></i>
          <i class="el-icon-edit" @click="addDepartment(index)"></i>
          <i class="el-icon-delete" @click="removeDepartment(index)"></i>
        </div>
        <div class="department post transition-box" v-show="!item.ifExpand" v-for="(val, key) in item.jobExpList" :key="key">
          <div class="department-title post-title">
            <i></i>
            <span>{{ val.jobName }}</span>
            <span style="color: #191919">{{ val.staffNumber }}人</span>
            <div class="splice"></div>
            <span style="color: #191919">每班接触时长{{ val.exposureHd }}(h/d)</span>
            <div class="splice"></div>
            <span style="color: #191919">每周接触天数{{ val.exposureDw }}(d/w)</span>
            <div class="splice"></div>
            <i class="el-icon-plus" @click="addHarm(index, key)"></i>
            <i class="el-icon-edit" @click="addPost(index, key)"></i>
            <i class="el-icon-delete" @click="removePost(index, key)"></i>
          </div>
          <el-form class="harmForm" :inline="true" ref="harmForm" :model="val" label-position="right" :rules="harmRules">
            <el-table class="zwx-table" style="min-width: 1200px;" :row-key="getRid()" :key="tabRef" :data="val.hazardsExpList" border stripe>
              <el-table-column prop="hazardsName" label="职业病危害因素" :width="190" header-align="center" align="left">
                <template slot-scope="{ row }">
                  <span>{{ row.hazardsName != null ? row.hazardsName : '--' }}</span>
                </template>
              </el-table-column>

              <!-- <el-table-column label="性质" :width="120" header-align="center" align="left">
                <template #default="{ row }">
                  <div>
                    <span v-if="row.hazardNature === '' || row.hazardNature === undefined" style="color: #c0c4cc;"></span>
                    <span v-else>{{ row.hazardNature ? '严重' : '一般' }}</span>
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column label="检测项目" :width="140" header-align="center" align="left">
                <template #default="{ row }">
                  {{ validLine(row.detectionItemName) }}
                </template>
              </el-table-column>
              <el-table-column prop="checkPointName" label="检测点位" :width="190" header-align="center" align="left">
                <template slot-scope="{ row }">
                  <span>{{ row.checkPointName != null ? row.checkPointName : '--' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="检测结果" header-align="center">
                <el-table-column label="CTWA" :width="120" header-align="center" align="center" :render-header="renderHeaderLabel">
                  <template #default="{ row }">{{ row.tagTwa == 2 ? '<' : '' }} {{ validLine(row.valueTwa) }}</template>
                </el-table-column>
                <el-table-column label="CSTE" :width="120" header-align="center" align="center" :render-header="renderHeaderLabel">
                  <template #default="{ row }">
                    <span>{{ row.tagStel == 2 ? '<' : '' }} {{ validLine(row.valueStel) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="CME" :width="120" header-align="center" align="center" :render-header="renderHeaderLabel">
                  <template #default="{ row }">{{ row.tagMe == 2 ? '<' : '' }} {{ validLine(row.valueMe) }}</template>
                </el-table-column>
                <el-table-column label="CPE" :width="120" header-align="center" align="center" :render-header="renderHeaderLabel">
                  <template #default="{ row }">{{ row.tagPe == 2 ? '<' : '' }} {{ validLine(row.valuePe) }}</template>
                </el-table-column>
                <el-table-column label="噪声等效声级" :width="140" header-align="center" align="center">
                  <template #default="{ row }">
                    {{ validLine(row.noiseLex) }}
                  </template>
                </el-table-column>
                <el-table-column label="其他因素" :width="140" header-align="center" align="center">
                  <template #default="{ row }">
                    {{ validLine(row.otherValue) }}
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="判定结果" :width="160" header-align="center" align="center">
                <template #default="{ row }">
                  <span v-if="row.judgementResult === '' || row.judgementResult === undefined">{{ '--' }}</span>
                  <span v-else>{{ row.judgementResult ? '合格' : '超标' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" header-align="center" align="left" :min-width="150">
                <template slot-scope="scope">
                  <el-button class="zwx-button zwx-button-text-26" type="text" @click="editHarm(scope, index, key)">编辑</el-button>
                  <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteItem(scope, index, key)">删除</el-button>
                  <!-- <el-button class="zwx-button zwx-button-text-26" type="text" @click="copy(scope, index, key)">复制</el-button> -->
                  <span class="warning">{{ scope.row.warningText }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
      </div>
    </div>
    <base-headline title="检测结论及建议" />
    <el-form class="zwx-form edit-form" ref="endForm" key="endForm" :model="endForm" :rules="endRules" label-position="right" @submit.native.prevent>
      <div class="edit-row">
        <el-form-item label-width="112px" label="检测结论：" prop="detectionConclusion">
          <el-input class="zwx-textarea" type="textarea" style="width: 888px !important;" v-model.trim="endForm.detectionConclusion" :autosize="{ minRows: 4 }" resize="none" placeholder="请输入" maxlength="500" clearable />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="112px" label="建议：" prop="reportSuggest">
          <el-input class="zwx-textarea" type="textarea" style="width: 888px !important;" v-model.trim="endForm.reportSuggest" :autosize="{ minRows: 4 }" resize="none" placeholder="请输入" maxlength="500" clearable />
        </el-form-item>
      </div>
    </el-form>

    <!-- 危害因素编辑弹出框 -->
    <base-dialog ref="editDialog" class="edit-dialog ssas" title="编辑" @determine="addHarzForm" @cancel="closeHarz" @close="closeHarz">
      <el-form class="harzinput" label-width="140px" ref="harzForm" :inline="true" style="padding-top:20px;" label-position="right" :model="harzForm" :rules="harzRules">
        <el-form-item label-width="140px" label="危害因素：">
          <div style="display: block;line-height: 20px;width: 160px;height: 40px;display: flex;align-items: center;">{{ harzForm.hazardsName }}</div>
          <!-- <el-input class="zwx-input ms-select-simple-width-200" v-model="harzForm.hazardsName" disabled clearable /> -->
        </el-form-item>

        <el-form-item label-width="140px" label="检测项目：" prop="detectionItemName">
          <el-select class="zwx-select ms-select-simple-width-200" popper-class="zwx-select-popper" v-model="harzForm.detectionItemName" placeholder="请选择" :filterable="true" @change="changeHarz">
            <el-option :label="item.itemName" :value="item.itemName" v-for="item in harmMap[harzForm.hazardsCode || harzForm.codeNo]" :key="item.codeNo"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="140px" label="检测点位：" prop="checkPointName">
          <el-input class="zwx-input ms-select-simple-width-200" v-model="harzForm.checkPointName" clearable maxlength="50"/>
        </el-form-item>
        <!-- <el-form-item label-width="140px" label="接触水平：" prop="contactLevel">
          <el-select class="zwx-select ms-select-simple-width-200" popper-class="zwx-select-popper" v-model="harzForm.contactLevel" placeholder="请选择" :filterable="true">
            <el-option :label="item.label" :value="item.value" v-for="item in contactLevelList" :key="item.codeNo"></el-option>
          </el-select>
        </el-form-item> -->
        <div style="margin-bottom:20px;">
          <base-headline title="职业病危害因素接触情况">
            <span style="color: #F56C6C;font-size: 12px;margin-left:15px;">{{ warningResult }}</span>
          </base-headline>
        </div>

        <!-- <div v-if="!(harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001')">
          <el-form-item label="CTWA：" prop="valueTwa">
            <template slot="label">
              <span>C</span>
              <sub>TWA</sub>
              <span>：</span>
            </template>
            <el-input class="zwx-input" v-model="harzForm.valueTwa" maxlength="50" auto-complete="off" :controls="false" placeholder="请输入" :disabled="harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001'" />
          </el-form-item>
          <el-form-item prop="valueStel">
            <template slot="label">
              <span>C</span>
              <sub>STE</sub>
              <span>：</span>
            </template>
            <el-input class="zwx-input" v-model="harzForm.valueStel" maxlength="50" auto-complete="off" :controls="false" placeholder="请输入" :disabled="harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001'" />
          </el-form-item>
          <el-form-item prop="valueMe">
            <template slot="label">
              <span>C</span>
              <sub>ME</sub>
              <span>：</span>
            </template>
            <el-input class="zwx-input" v-model="harzForm.valueMe" maxlength="50" auto-complete="off" :controls="false" placeholder="请输入" :disabled="harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001'" />
          </el-form-item>
          <el-form-item prop="valuePe">
            <template slot="label">
              <span>C</span>
              <sub>PE</sub>
              <span>：</span>
            </template>
            <el-input class="zwx-input" v-model="harzForm.valuePe" maxlength="50" auto-complete="off" :controls="false" placeholder="请输入" :disabled="harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001'" />
          </el-form-item>
        </div>
        <div>
          <el-form-item label="噪声等效声级：" prop="noiseLeq" v-if="!(harzForm.codeNo != '130001' && harzForm.hazardsCode != '130001')">
            <el-input class="zwx-input" v-model="harzForm.noiseLeq" maxlength="50" placeholder="请输入" :disabled="harzForm.codeNo != '130001' && harzForm.hazardsCode != '130001'" auto-complete="off" :controls="false" />
          </el-form-item>
          <el-form-item label="其他因素：" prop="otherValue">
            <el-input class="zwx-input" v-model="harzForm.otherValue" placeholder="请输入" maxlength="50" auto-complete="off" :controls="false" />
          </el-form-item>
        </div> -->
        <div v-if="!(harzForm.codeNo == '130001' || harzForm.hazardsCode == '130001')">
          <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdTwa)" label-width="140px" prop="valueTwa" style="padding-bottom: 5px;">
            <!-- <el-input class="zwx-input" v-model="postForm.jobName" placeholder="请输入" maxlength="20" clearable /> -->
            <template slot="label">
              C
              <sub>TWA</sub>
              ：
            </template>
            <el-input placeholder="请输入内容" v-model="harzForm.valueTwa" class="zwx-input zwx-select2" @input="$validate.number(harzForm, 'valueTwa', 4, 5)" auto-complete="off" :controls="false">
              <el-select popper-class="zwx-select-popper" v-model="harzForm.tagTwa" slot="prepend" placeholder="" @change="e => deepChange(e, harzForm, 'tagTwa')">
                <el-option label="=" :value="1"></el-option>
                <el-option label="<" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdStel)" label-width="142px" prop="valueStel" style="padding-bottom: 5px;">
            <template slot="label">
              C
              <sub>STE</sub>
              ：
            </template>
            <el-input placeholder="请输入内容" v-model="harzForm.valueStel" class="zwx-input zwx-select2" @input="$validate.number(harzForm, 'valueStel', 4, 5)" auto-complete="off" :controls="false">
              <el-select v-model="harzForm.tagStel" slot="prepend" placeholder="" @change="e => deepChange(e, harzForm, 'tagStel')">
                <el-option label="=" :value="1"></el-option>
                <el-option label="<" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdMac)" label-width="142px" prop="valueMe" style="padding-bottom: 5px;">
            <template slot="label">
              C
              <sub>ME</sub>
              ：
            </template>
            <el-input placeholder="请输入内容" v-model="harzForm.valueMe" class="zwx-input zwx-select2" @input="$validate.number(harzForm, 'valueMe', 4, 5)" auto-complete="off" :controls="false">
              <el-select v-model="harzForm.tagMe" slot="prepend" placeholder="" @change="e => deepChange(e, harzForm, 'tagMe')">
                <el-option label="=" :value="1"></el-option>
                <el-option label="<" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item v-if="!($zwxBase.verifyIsBlank(selectProject.itemStdTwa) || !$zwxBase.verifyIsBlank(selectProject.itemStdStel))" label-width="140px" prop="valuePe" style="padding-bottom: 5px;">
            <template slot="label">
              C
              <sub>PE</sub>
              ：
            </template>
            <el-input placeholder="请输入内容" v-model="harzForm.valuePe" class="zwx-input zwx-select2" @input="$validate.number(harzForm, 'valuePe', 4, 5)" auto-complete="off" :controls="false">
              <el-select v-model="harzForm.tagPe" slot="prepend" placeholder="" @change="e => deepChange(e, harzForm, 'tagPe')">
                <el-option label="=" :value="1"></el-option>
                <el-option label="<" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label-width="140px" label="噪声声效等级：" prop="noiseLex" style="padding-bottom: 5px;">
            <el-input placeholder="请输入内容" v-model="harzForm.noiseLex" class="zwx-input" @input="$validate.number(harzForm, 'noiseLex', 4, 2)" auto-complete="off" :controls="false"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label-width="140px" label="其他因素：" prop="otherValue" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="harzForm.otherValue" auto-complete="off" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label-width="140px" label="判定结果：" prop="judgementResult" style="padding-bottom: 5px;">
            <el-radio-group class="zwx-radio-group" v-model="harzForm.judgementResult" @input="i => deepChange(i, harzForm, 'judgementResult')">
              <el-radio class="zwx-radio" :label="1">合格</el-radio>
              <el-radio class="zwx-radio" :label="0">超标</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>

    <!-- 添加部门弹出框 -->
    <base-dialog class="edit-dialog" :pagination="false" :title="ifEdit ? '编辑部门' : '添加部门'" @determine="addForms" @cancel="close" @close="close" ref="departmentDialog" :if-show-close="true">
      <el-form class="zwx-form ss" ref="msForm" style="padding-left:30px;padding-top:20px;" :model="msForm" label-position="right" :rules="rules">
        <el-form-item label-width="110px" class="simp" label="车间部门：" style="margin-bottom: 25px !important;" prop="workplaceName">
          <el-input class="zwx-input width90" v-model="msForm.workplaceName" placeholder="请输入" clearable maxlength="50"/>
        </el-form-item>
      </el-form>
      <div class="continuation" slot="footer">
        <i class="el-icon-plus"></i>
        <span @click="addForms(true)">连续添加</span>
      </div>
    </base-dialog>

    <!-- 添加岗位弹出框 -->
    <base-dialog class="edit-dialog" :pagination="false" title="添加岗位" @determine="addPostForm" @cancel="closePost" @close="closePost" ref="postDialog" :if-show-close="true">
      <el-form class="zwx-form ss" ref="postForm" style="padding-left:30px;padding-top:20px;" :model="postForm" label-position="right" :rules="postRules">
        <el-form-item label-width="110px" style="margin-bottom: 20px !important;" label="车间部门：" prop="workplaceName">
          <el-input class="zwx-input width90" v-model="postForm.workplaceName" disabled clearable />
        </el-form-item>
        <el-form-item class="simp" label-width="110px" style="margin-bottom: 20px !important;" label="岗位工种：" prop="jobName">
          <el-input class="zwx-input width90" v-model="postForm.jobName" placeholder="请输入" clearable maxlength="50"/>
        </el-form-item>
        <el-form-item class="simp" label-width="110px" style="margin-bottom: 20px !important;" label="人数：" prop="staffNumber">
          <el-input class="zwx-input width90" v-model="postForm.staffNumber" @input="inptJy" placeholder="请输入" clearable maxlength="50"/>
        </el-form-item>
        <el-form-item label-width="110px" label="每班接触时长：" prop="exposureHd" style="padding-bottom: 5px;margin-bottom: 20px !important;">
          <el-input class="zwx-input" style="width: 234px !important;" v-model="postForm.exposureHd" maxlength="10" @input="inputExposureHd" placeholder="请输入" clearable />
          <div class="unit">h/d</div>
        </el-form-item>
        <el-form-item label-width="110px" label="每周接触天数：" prop="exposureDw" style="padding-bottom: 5px;">
          <el-input class="zwx-input" style="width: 234px !important;" v-model="postForm.exposureDw" maxlength="10" @input="inputExposureDw" placeholder="请输入" clearable />
          <div class="unit">d/w</div>
        </el-form-item>
      </el-form>
      <div class="continuation" slot="footer">
        <i class="el-icon-plus"></i>
        <span @click="addPostForm(true)">连续添加</span>
      </div>
    </base-dialog>

    <!-- 选择危害因素弹框 -->
    <div class="harm">
      <base-dialog class="edit-dialog" :pagination="false" title="选择危害因素" @determine="addHarmList" @cancel="closeHarm" @close="closeHarm" ref="harmDialog" :if-show-close="true">
        <div style="display:flex;">
          <!-- 职业病选择左侧部分 -->
          <div class="hazardFactor-list2">
            <div class="hazardFactor-search">
              <el-input style="width: 270px !important; margin-right: 10px !important" class="zwx-input" :ref="searchRef3" placeholder="搜索信息" v-model="searchInput3">
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
            <div style="height: 440px">
              <zwx-scrollbar-y style="height: 100%">
                <el-tree class="filter-tree" empty-text="暂无数据" :data="gbzOhsSuspectedCodeNoTreeList" :props="defaultProps3" show-checkbox @check-change="handleCheckChange3" accordion node-key="codeNo" ref="tree3" :filter-node-method="filterNodeMethod3" :expand-on-click-node="true" :default-expanded-keys="checkRidArr3" :check-strictly="isCheckStrictly3">
                  <div class="custom-tree-node" slot-scope="{ node, data }" style="display: flex; justify-content: space-between; width: 100%">
                    <div style="display: flex">
                      <el-tooltip class="item" effect="light" :disabled="node.label.length < (data.cas ? 6 : 20)" :content="data.hazardsName" placement="top" popper-class="contact-tooltip">
                        <div class="name-hidden">
                          <span :class="{ hatext: data.cas }">{{ node.label }}</span>
                          <span>{{ data.cas }}</span>
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                </el-tree>
              </zwx-scrollbar-y>
            </div>
          </div>
          <!-- 职业病选择右侧部分 -->
          <div class="equipment-detail2">
            <div style="padding: 10px 0px 10px 12px;border-bottom:1px solid #CDCDCC ">已选危害因素({{ showOhsSuspectedCodeNoList.length }})</div>
            <!-- 列表展示部分 -->
            <el-table height="461" width="100%" ref="peResultDetailListTable" class="zwx-table" row-key="itemCode" border stripe :data="showOhsSuspectedCodeNoList" :show-header="false">
              <el-table-column width="330" prop="hazardsName" label="职业病名称" header-align="center" align="left">
                <template slot-scope="{ row }">
                  {{ row.hazardsName + (row.cas || '') }}
                </template>
              </el-table-column>
              <el-table-column label="操作" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-delete" @click="delCheckResultDetail3(scope.$index)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </base-dialog>
    </div>
    <!-- 关联检测报告弹框 -->
    <!-- <detection-dialog ref="detectionDialog" :isData="this.deptExpList.length != 0" @detectionSubmit="detectionSubmit"></detection-dialog> -->

    <div style="position:absolute;opacity:0;display: none;">
      <risk-hazard-factor-multiple ref="peHazardFactorMultipleEditRef" @sss="sss" :key="hazardFactorKey" :innerWidth="610" :options="gbzOhsSuspectedCodeNoTreeList" dataPyxNameFiled="hazardsName" dataNameFiled="hazardsName" showNameFiled="hazardName" dataCodeFiled="codeNo" dataUpCodeFiled="upCodeNo" @change="sss"></risk-hazard-factor-multiple>
    </div>
    <fixed-footer>
      <!-- <el-button class="zwx-button zwx-button-32" @click="back()">上一步</el-button> -->
      <el-button class="zwx-button zwx-button-32" @click="save(0)">暂存</el-button>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="save(1)">提交</el-button>
    </fixed-footer>
  </div>
</template>
<script>
// import ZwxStep from '@/views/workplace_detection/components/ZwxStep'
import harmDialog from './components/harmDialog'
import postDialog from './components/postDialog'
import projectDialog from './components/projectDialog'
import RiskHazardFactorMultiple from './components/RiskHazardFactorMultiple'
export default {
  name: 'DetectionReportDetectionResult',
  components: { harmDialog, postDialog, projectDialog, RiskHazardFactorMultiple },
  data() {
    return {
      api: this.$store.state.api,
      rid: this.$route.params.rid,
      employerUuid: this.$route.params.employerUuid,
      workers: this.$route.params.workers,
      deptExpList: [],
      ifEdit: false,
      ifPostEdit: false,
      selectDepartmentIndex: 0,
      selectPostIndex: 0,
      msForm: {},
      postForm: {},
      endForm: {},
      endRules: {
        detectionConclusion: [{ required: true, message: '请输入检测结论', trigger: ['change', 'blur'] }],
        reportSuggest: [{ required: true, message: '请输入建议', trigger: ['change', 'blur'] }],
      },
      rules1: {
        detectionType: [{ required: true, message: '请选择检测类型', trigger: ['change', 'blur'] }],
        reportDate: [{ required: true, message: '请选择报告日期', trigger: ['change', 'blur'] }],
        reportName: [{ required: true, message: '请输入检测报告名称', trigger: ['change', 'blur'] }],
        ifClassifiedUnit: [{ required: true, trigger: ['change', 'blur'] }],
        annexList1: [{ required: true, message: '请上传报告附件', trigger: ['change', 'blur'] }],
        reportCode: [
          { required: true, message: '请输入报告编号', trigger: ['blur'] },
          { validator: this.reportCodeIfExist, trigger: 'blur' },
        ],
        employerName: [
          { required: true, message: '请输入单位名称', trigger: ['blur'] },
          { validator: this.validateUnitName, trigger: ['blur', 'change'] },
        ],
        creditCode: [
          { required: true, message: '请输入社会信用代码', trigger: ['blur'] },
          { validator: this.validateCreditCode, trigger: ['blur'] },
        ],
        bizZone: [
          { required: true, message: '请选择行政区划', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        // productName: [
        //   { required:!addForm.ifClassifiedUnit, message: '请输入产品名称', trigger: 'blur' }
        // ],
        // annualOutput: [
        //   { required: !addForm.ifClassifiedUnit, message: '请输入年产量', trigger: 'blur' },
        //   { type: 'number', message: '年产量必须是数字', trigger: 'blur' }
        // ],
      },
      rules: {
        workplaceName: { required: true, message: '请输入车间部门', trigger: 'blur' },
      },
      addForm: {
        rid: this.$route.params.rid,
        detectionType: null,
        reportType: this.$route.params.reportType,
        organName: null,
        reportDate: null,
        reportName: null,
        reportCode: null,
        annexList1: [],
        dataState: null,
        employerName: '',
        creditCode: '',
        bizZone: '',
        zoneType: '',
        ifClassifiedUnit: false,
        products: [],
      },
      contactLevelList: [
        { label: '符合', value: false },
        { label: '不符合', value: true },
      ],
      hazardNatureMap: [
        { codeName: '一般', codeNo: 0 },
        { codeName: '严重', codeNo: 1 },
      ],
      unitOptionList: [],
      harmRules: {
        contactLevel: { required: true, message: '必填', trigger: 'blur' },
      },
      postRules: {
        jobName: { required: true, message: '请输入岗位工种', trigger: 'blur' },
        staffNumber: { required: true, message: '请输入人数', trigger: 'blur' },
        exposureHd: { required: true, validator: this.exposureHdRules, trigger: 'blur' },
        exposureDw: { required: true, validator: this.exposureDwRules, trigger: 'blur' },
      },
      gbzOhsSuspectedCodeNoTreeList: [],
      defaultProps3: {
        children: 'children',
        label: 'hazardsName',
        code: 'codeNo',
      },
      testingItemsList: [],
      showOhsSuspectedCodeNoList: [], //选中的疑似职业病集合
      checkRidArr3: [], //编辑默认展开的节点的 key 的数组
      isCheckStrictly3: false,
      searchInput3: '',
      searchRef3: '332a',
      hazardFactorKey: '32',
      harmMap: {},
      tabRef: 'tabRef',
      employerUuid: undefined,
      harzForm: {},
      warningResult: '',
      reportItem: null,
      harzRules: {
        hazardNature: { required: true, message: '必选', trigger: ['blur', 'change'] },
        detectionItemName: { required: true, message: '请选择检测项目', trigger: ['blur', 'change'] },
        contactLevel: { required: true, message: '必选', trigger: ['blur', 'change'] },
        checkPointName: [{ required: true, message: '必填', trigger: ['blur', 'change'] }],
        // valueStel: { required: true, message: '必填', trigger: ['blur', 'change'] },
        // valueTwa: { required: true, message: '必填', trigger: ['blur', 'change'] },
        // valueMe: { required: true, message: '必填', trigger: ['blur', 'change'] },
        // valuePe: { required: true, message: '必填', trigger: ['blur', 'change'] },
        noiseLex: { required: true, message: '必填', trigger: ['blur', 'change'] },
        judgementResult: { required: true, message: '必选', trigger: ['blur', 'change'] },
      },
      selectProject: {},
      uuid: null,
      requestUrl: '/basic/info/getEmployerInfoByUnitNameLike-1',
      creditCodeDisabled: true,
      zoneDisabled: true,
      zoneCode12From: '000000000000',
    }
  },
  watch: {
    searchInput3() {
      this.search3(this.searchInput3)
    },
    'addForm.annexList1': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          this.$refs.addForm.validateField('annexList1')
          this.$forceUpdate()
        })
      },
      deep: true,
    },
    'addForm.ifClassifiedUnit': {
      handle(newVal, oldVal) {
        if (newVal) {
          this.addForm.products = []
        }
      },
      deep: true,
    },
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  created() {
    this.$system.get(
      this.api + '/systematic/getParameterListByParameterKeys-0',
      { parameterKeys: 'SYSTEM_ZONE_CODE' },
      false,
      true,
      data => {
        if (data.type === '00' && data.parameterList.length > 0) {
          this.zoneCode12From = data.parameterList[0].parameterValue
          this.$nextTick(() => {
            this.$refs.zoneCodeArea.initSearch()
          })
        } else if (data.type === '99') {
          this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
        } else {
          this.$notify.error({ title: '错误', message: data.mess })
        }
      },
      this.error
    )
  },
  mounted() {
    if (this.$route.params.uuid) {
      let data = {
        uuid: this.$route.params.uuid,
      }
      this.$system.post(this.api + '/workplace/detection/getDetectionReport-1', data, true, true, data => {
        this.uuid = data.resultInfo.uuid
        this.addForm.uuid = data.resultInfo.uuid
        this.addForm.detectionType = data.resultInfo.detectionType
        this.addForm.organName = data.resultInfo.organName
        this.addForm.reportDate = data.resultInfo.reportDate
        this.addForm.reportName = data.resultInfo.reportName
        this.addForm.reportCode = data.resultInfo.reportCode
        this.addForm.employerName = data.resultInfo.employerName
        this.addForm.bizZone = data.resultInfo.bizZone
        this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)

        this.addForm.creditCode = data.resultInfo.employerCreditCode
        //给天眼查组件赋值后置灰------开始
        this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-delete'
        this.$refs.unitSelectRef.unitNameDisabled = true
        // 给天眼查组件赋值后置灰------结束
        // this.addForm.organCreditCode = data.resultInfo.organCreditCode
        // this.addForm.organUid = data.resultInfo.organUid
        this.addForm.annexList1 = []
        if (data.resultInfo.reportAnnexExpList1.length) {
          this.addForm.annexList1.push({
            fileName: data.resultInfo.reportAnnexExpList1[0].fileName,
            filePath: data.resultInfo.reportAnnexExpList1[0].filePath,
            check: false,
            fileIconShow: 'colorful chis-icon-colorful-pdf',
          })
        }
        setTimeout(() => {
          this.$nextTick(() => {
            this.$refs['addForm'].clearValidate()
          })
        }, 0)

        this.addForm.ifClassifiedUnit = data.resultInfo.ifClassifiedUnit
        this.addForm.products = data.resultInfo.detectionProductExpList
        this.endForm = {
          detectionConclusion: data.resultInfo.detectionConclusion,
          reportSuggest: data.resultInfo.reportSuggest,
        }
        this.deptExpList = data.resultInfo.newReportWorkplaceExpList.map(x => {
          x.jobExpList = x.newDetectionReportJobExpList.map(y => {
            var reportHazardExpList = []
            y.newReportHazardExpList.map(z => {
              z.newDetectionReportHazardsExpList.map(i => {
                var i = { ...i }
                let detectionItemName = i.detectionItemName
                i = Object.assign(z, i)
                i.hazardsName = z.hazardsName
                i.hazardsCode = z.hazardsCode
                i.detectionItemName = detectionItemName
                var newI = { ...i, detectionItemName: i.detectionItemName }
                reportHazardExpList.push(newI)
              })
              return z
            })
            y.hazardsExpList = reportHazardExpList
            y.reportHazardExpList = reportHazardExpList
            return y
          })

          return x
        })
      })
    }

    // if (this.$zwxBase.verifyIsNotBlank(this.$route.params.employerUuid)) {
    //   this.employerUuid = this.$route.params.employerUuid
    // }
    // if (!this.$zwxBase.verifyIsBlank(this.$route.params.rid)) {
    //   this.queryDetectionReportBasicInfo(this.$route.params.rid)
    // }
    // if (this.rid) {
    //   this.$emit('updateCrumbs', '编辑')
    // } else {
    //   this.$emit('updateCrumbs', '添加')
    // }
    // 获取部门车间
    this.getHazardsFactorStandardShow()
    this.getUnits()
  },
  methods: {
    getUnits() {
      const data = {
        codeTypeCodes: '2002',
      }
      this.$system.get(
        this.api + '/systematic/getSimpleCodeListByCodeTypeCodesMap-0',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.unitOptionList = data.resultMap[2002].map(i => {
              return {
                label: i.codeName,
                value: i.codeNo,
              }
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    nativePlaceChange() {
      if (this.$refs.zoneCodeArea.getChooseArea()) {
        this.addForm.zoneType = this.$refs.zoneCodeArea.getChooseArea().zoneType
        this.addForm.bizZone = this.$refs.zoneCodeArea.getChooseArea() == null ? null : this.$refs.zoneCodeArea.getChooseArea().zoneCode12
      }
    },
    //机构未搜索到数据
    unitNameSearch() {
      this.creditCodeDisabled = true
      this.zoneDisabled = true
      this.$refs.unitSelectRef.unitNameSearchVisible = false
      this.$system.notify('提示', '未检索到单位信息', 'info')
    },
    //机构清空回调方法
    unitNameDel() {
      this.zoneCode12From = '320000000000'
      this.$refs.zoneCodeArea.initSearch()
      this.creditCodeDisabled = true
      // this.zoneDisabled = true
      this.searchUnitList = []
      this.addForm.creditCode = ''
      this.addForm.employerName = ''
      this.addForm.zoneType = ''
      this.zoneDisabled = false
      //天眼查组件清空赋值------开始
      this.$refs.unitSelectRef.searchOrDelIcon = 'el-icon-search'
      this.$refs.unitSelectRef.unitNameDisabled = false
      //给天眼查组件清空赋值------结束
      this.$nextTick(() => {
        this.$refs['addForm'].clearValidate()
      })
    },
    getProduct(uid) {
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/basic/info/getEmployerProductByUnitUid-1',
        { mainUid: uid },
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.addForm.products = data.productExpList
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    // 单位信息选择回调方法
    unitNameSelect(item) {
      //客户信息
      this.addForm.employerName = item.unitName
      this.addForm.creditCode = item.creditCode
      this.creditCodeDisabled = true
      this.employerUuid = item.unitUid || ''
      this.addForm.bizZone = item.bizZone || ''
      this.zoneDisabled = item.bizZone != undefined
      if (item.ifClassifiedUnit === false) {
        this.addForm.ifClassifiedUnit = false
        if (item.unitUid) {
          this.getProduct(item.unitUid)
        }
      } else {
        if (item.ifClassifiedUnit) {
          this.addForm.ifClassifiedUnit = item.ifClassifiedUnit
        }
        this.addForm.products = []
      }
      if (item.bizZone) {
        this.zoneCode12From = '000000000000'
      } else {
        this.zoneCode12From = '320000000000'
      }
      this.$refs.zoneCodeArea.initSearch(this.addForm.bizZone)
    },
    clickPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList1) {
          for (let j in fileList) {
            if (this.addForm.annexList1[i].fileName === fileList[j].fileName) {
              this.addForm.annexList1[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    clickPositive(showList) {
      showList.forEach(item => {
        let flag = this.addForm.annexList1.some(e => e.fileName == item)
        if (flag) {
          this.$refs.msPositive1.deleteIndex(showList.length - 1)
          return
        }
        this.addForm.annexList1.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
        })
      })
      this.generateFilesUid1()
      if (this.addForm.annexList1 != null && this.addForm.annexList1 != undefined && this.addForm.annexList1.length > 0) {
        this.$refs.addForm.clearValidate('annexList1')
      }
    },
    dealfilePrefix(file) {
      let prefix = file.substring(file.indexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      }
      return fileIconShow
    },
    clickPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.addForm.annexList1) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.addForm.annexList1) {
          for (let j in fileList) {
            if (this.addForm.annexList1[i].fileName === fileList[j].fileName) {
              this.addForm.annexList1[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.$emit('loading', false)
    },
    /**
     * 单个文件预览
     */
    openFilePreview(filename, filePath) {
      let previewFileList = []
      let item = {
        fileName: filename,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    clickError() {},
    mouseenter(index) {
      this.addForm.annexList1[index].check = true
    },
    mouseleave(index) {
      this.addForm.annexList1[index].check = false
    },
    validLine(i) {
      if (i == null) {
        i = '--'
      }
      if (i == undefined) {
        i = '--'
      }
      if (i == '') {
        i = '--'
      }
      return i
    },
    deepChange(val, item, name) {
      this.$set(item, name, val)
      this.$forceUpdate()
    },
    exposureHdRules(rule, value, callback) {
      if (Number(value) < 0 || Number(value) > 12) {
        return callback(new Error('请输入0~12(不包含12)'))
      }else{
        if(value){
          return callback()
        }else{
          callback(new Error('请输入'))
        }
      }
      
    },
    exposureDwRules(rule, value, callback) {
      if (Number(value) < 0 || Number(value) >= 7) {
        return callback(new Error('请输入0~7(不包含7)'))
      }else{
        if(value){
          return callback()
        }else{
          callback(new Error('请输入'))
        }
      }
    },
    changeHarz(item) {
      var arr = this.harmMap[this.harzForm.hazardsCode || this.harzForm.codeNo]
      this.selectProject = arr.filter(x => x.itemName == item)[0]
      var arg = {
        detectionItemCode: this.selectProject.itemCode,
        tagTwa: 1,
        tagStel: 1,
        tagMe: 1,
        tagPe: 1,
      }
      this.harzForm = Object.assign(this.harzForm, arg)
      // this.harzForm.detectionItemCode=this.selectProject.itemCode
      // this.harzForm.tagTwa=1
      // this.harzForm.tagStel=1
      // this.harzForm.tagMe=1
      // this.harzForm.tagPe=1
      // console.log(this.harmMap[this.harzForm.hazardsCode || this.harzForm.codeNo])
    },
    inputExposureHd(value) {
      this.postForm.exposureHd = this.oninput(value, 1)
    },
    inputExposureDw(value) {
      this.postForm.exposureDw = this.oninput(value, 1)
    },
    /**
     * 文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    detectionSubmit(data, reportItem) {
      this.reportItem = reportItem
      this.searchSuccess(data)
    },
    openDetection() {
      if (this.reportItem) {
        this.reportItem = null
        return
      }
      this.$refs.detectionDialog.show()
    },
    editHarm(scope, index, key) {
      this.selectDepartmentIndex = index
      this.selectPostIndex = key
      this.selectProject = {}
      this.harzForm = { ...scope.row }
      var arr = this.harmMap[this.harzForm.hazardsCode || this.harzForm.codeNo]
      this.harzForm.judgementResult = scope.row.judgementResult == undefined ? 1 : scope.row.judgementResult ? 1 : 0
      this.$set(this.harzForm, 'judgementResult', scope.row.judgementResult == undefined ? 1 : scope.row.judgementResult ? 1 : 0)
      if (this.harzForm.detectionItemName) {
        this.selectProject = arr.filter(x => x.itemName == this.harzForm.detectionItemName)[0]
      }

      this.harzIndex = scope.$index

      this.$refs.editDialog.show(true)

      this.$nextTick(() => {
        this.$refs.harzForm.clearValidate()
      })
    },
    copy(scope, index, key) {
      this.deptExpList[index].jobExpList[key].hazardsExpList.splice(scope.$index + 1, 0, { ...scope.row })
      this.$set(this, 'tabRef', new Date().getTime() + 'tabref')
    },
    deleteItem(row, index, key) {
      this.deptExpList[index].jobExpList[key].hazardsExpList.splice(row.$index, 1)
    },
    addHarzForm() {
      this.$refs['harzForm'].validate(valid => {
        if (valid) {
          if (this.vaildHarm(this.harzForm)) {
            this.warningResult = '请至少填写一项检测结果'
            return
          }
          this.warningResult = ''
          this.harzForm.warningText = ''
          this.$set(this.getPosiObjList(this.selectDepartmentIndex, this.selectPostIndex), [this.harzIndex], this.harzForm)
          this.$refs.editDialog.show(false)
        }
      })
    },
    closeHarz() {
      this.warningResult = ''
      this.$refs.harzForm.clearValidate()
      this.$refs.editDialog.show(false)
    },
    // validHarz(harzItem) {
    //   if (harzItem.codeNo == '130001' || harzItem.hazardsCode == '130001') {
    //     return !this.isBlank(harzItem.otherValue) || !this.isBlank(harzItem.noiseLeq)
    //   } else {
    //     return !this.vaildHarm(harzItem)
    //   }
    // },
    // 是否等于空
    isBlank(str) {
      return str == undefined || str.trim() == ''
    },
    renderHeaderLabel(h, { column }) {
      let firstVal = column.label.substring(0, 1)
      let otherVal = column.label.substring(1, column.label.length)
      return [h('span', firstVal, {}), h('sub', otherVal, {})]
    },
    getRid() {
      return 'rid' + new Date().getTime()
    },
    expand(item) {
      if (item.jobExpList.length == 0) return
      this.$set(item, 'ifExpand', !item.ifExpand)
    },
    sss(val) {
      this.gbzOhsSuspectedCodeNoTreeList = val
    },
    handleCheckChange3(data, checked, indeterminate) {
      let showOhsSuspectedCodeNoList = this.$refs.tree3 && this.$refs.tree3.getCheckedNodes(true)
      this.$set(this, 'showOhsSuspectedCodeNoList', showOhsSuspectedCodeNoList)
    },
    delCheckResultDetail3(index) {
      let node = this.showOhsSuspectedCodeNoList[index]
      this.$refs.tree3.setChecked(node.codeNo, false, true)
      this.showOhsSuspectedCodeNoList.splice(index, 1)
    },
    filterNodeMethod3(query, data) {
      // return true
      if (query === undefined || query === null || query === '') return true
      return data.hazardName.indexOf(query) !== -1
    },
    search3(searchInput) {
      this.searchInput3 = searchInput
      this.$refs['tree3'] && this.$refs['tree3'].filter(this.searchInput3)
    },
    /**
     * 获取危害因素数据源
     * */
    getHazardsFactorStandardShow() {
      this.$system.get(
        this.api + '/basic/info/getHazardsStandardExpListIncludeLimit-1',
        null,
        true,
        true,
        data => {
          if (data.type == '00') {
            let list = data.dataList || []
            this.gbzOhsSuspectedCodeNoTreeList = list.map(item => {
              if ((!item.limitExpList || item.limitExpList.length == 0) && item.levelCodeNo.split('.').length > 1) {
                item.disabled = true
              }
              return item
            })
            this.gbzOhsSuspectedCodeNoTreeList.forEach(item => {
              this.harmMap[item.codeNo] = item.limitExpList || []
            })
            // this.search()
            this.hazardFactorKey = new Date().getTime()
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    inptJy(value) {
      if (value == 0) {
        this.postForm.staffNumber = ''
        return
      }
      this.postForm.staffNumber = value.replace(/[^\d]/g, '')
    },
    addDepartment(index) {
      this.ifEdit = index != undefined
      if (index != undefined) {
        this.selectDepartmentIndex = index
        this.msForm = { ...this.deptExpList[index] }
      }
      this.$refs['departmentDialog'].show(true)
    },
    removeDepartment(index) {
      this.$msgbox({ title: '提示', message: '确定要删除此车间部门吗？', confirmButtonText: '确定', showCancelButton: '取消', type: 'warning', closeOnClickModal: false }).then(() => {
        this.deptExpList.splice(index, 1)
      })
    },
    addPost(index, key) {
      this.ifPostEdit = key != undefined
      if (key != undefined) {
        this.selectPostIndex = key
        this.$set(this, 'postForm', { ...this.getPosiObjList(index)[key] })
        this.$set(this.postForm, 'workplaceName', this.deptExpList[index].workplaceName)
      } else {
        this.selectDepartmentIndex = index
        this.$set(this.postForm, 'workplaceName', this.deptExpList[index].workplaceName)
      }
      this.$refs['postDialog'].show(true)
    },
    removePost(index, key) {
      this.$msgbox({ title: '提示', message: '确定要删除此岗位工种吗？', confirmButtonText: '确定', showCancelButton: '取消', type: 'warning', closeOnClickModal: false }).then(() => {
        this.deptExpList[index].jobExpList.splice(key, 1)
      })
    },
    addHarm(index, key) {
      this.selectDepartmentIndex = index
      this.selectPostIndex = key
      this.$refs['harmDialog'].show(true)
    },
    addHarmList() {
      let list = []
      if (this.showOhsSuspectedCodeNoList.length > 100) {
        this.$system.notify('错误', '单次最多添加不超过100个危害因素', 'error')
        return
      }
      list = this.showOhsSuspectedCodeNoList.map(item => {
        if (item.contactLevel != 0 && item.contactLevel != 1) {
          this.$set(item, 'contactLevel', true)
        }
        item.warningText = ''
        return { ...item }
      })
      this.showOhsSuspectedCodeNoList = []
      this.getPosiObjList(this.selectDepartmentIndex, this.selectPostIndex, list)
      this.harmClose()
    },
    deleteItem(row, index, key) {
      this.deptExpList[index].jobExpList[key].hazardsExpList.splice(row.$index, 1)
    },
    copy(scope, index, key) {
      this.deptExpList[index].jobExpList[key].hazardsExpList.splice(scope.$index + 1, 0, { ...scope.row })
      this.$set(this, 'tabRef', new Date().getTime() + 'tabref')
    },
    propEvent2(val) {
      if (val) {
        this.postForm.jobName = val.codeName
      }
    },
    search() {
      this.$emit('loading', true)
      let data = { fkByMainId: { rid: this.rid } }
      this.$system.postJson(this.api + '/oh/classification/getRiskClassificationDeptList-1', data, true, true, this.searchSuccess, this.error)
    },
    searchSuccess(data) {
      if (data.type === '00') {
        this.deptExpList = data.resultList
        this.fkBySelfInspectionId = data.fkBySelfInspectionId && data.fkBySelfInspectionId.rid
        if (data.detectionReportCode) {
          this.reportItem = {
            reportCode: data.detectionReportCode,
            reportDate: data.detectionReportDate,
            reportAnnexExpList1: data.detectionReportAnnexExpList.map(item => {
              item.fileName = item.annexName
              item.filePath = item.annexPath
              return item
            }),
          }
        }
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    addForms(ifContinuation) {
      this.$refs['msForm'].validate(valid => {
        if (valid) {
          if (this.ifEdit && !ifContinuation) {
            this.deptExpList[this.selectDepartmentIndex].workplaceName = this.msForm.workplaceName
          } else {
            this.deptExpList.push({ ...this.msForm, jobExpList: [] })
          }
          this.close(ifContinuation)
        }
      })
    },
    addPostForm(ifContinuation) {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          if (this.ifPostEdit && !ifContinuation) {
            this.deptExpList[this.selectDepartmentIndex].jobExpList[this.selectPostIndex] = { ...this.postForm }
          } else {
            this.deptExpList[this.selectDepartmentIndex].jobExpList.push({ ...this.postForm, hazardsExpList: [], ifExpand: false })
          }
          if (ifContinuation) {
            this.postForm.jobName = ''
            this.postForm.jobName = ''
            this.postForm.staffNumber = ''
          }
          this.closePost(ifContinuation)
        }
      })
    },
    save(dataState) {
      if (dataState == 0) {
        this.$refs['addForm'].validateField('employerName', error => {
          if (!error) {
            this.$refs['addForm'].validateField('detectionType', error => {
              if (!error) {
                this.checkData(dataState)
              }
            })
          }
        })
      } else {
        this.checkData(dataState)
      }

      // var newData={
      //   rid: this.rid,
      //   dataState:ifNext?1:0,
      //   newReportWorkplaceExpList,
      //   fillingStep:2,
      //   uuid:this.uuid
      // }
      // this.$system.postJson(this.api + '/oh/workplace/detection/addOrModifyTdDetectionReport-1',newData, true, true, data=>this.saveSuccess(data,ifNext), this.error, ifNext)
    },
    addProduct() {
      this.addForm.products.push({ productName: '', annualOutput: '', unit: '' })
    },
    removeRow(index) {
      this.addForm.products.splice(index, 1)
    },
    checkData(dataState) {
      let count = 0
      let vaildo = true
      if (dataState == 0) {
        if (count > 100) {
          this.$system.notify('错误', '危害因素总数不能超过100', 'error')
          return
        }
        var newReportWorkplaceExpList = this.deptExpList.map(x => {
          var item = {}
          item.workplaceName = x.workplaceName
          item.newDetectionReportJobExpList = x.jobExpList.map(y => {
            var newDetectionReportJobExpList = {}
            newDetectionReportJobExpList = JSON.parse(JSON.stringify(y))
            // newDetectionReportJobExpList=newDetectionReportJobExpList.map(x=>{
            //   if(x.codeNo){
            //     x.hazardsCode=x.codeNo
            //   }
            //   return x
            // })
            var newArr = [...new Set(y.hazardsExpList.map(x => x.hazardsCode || x.codeNo))]
            
            newDetectionReportJobExpList.newReportHazardExpList = newArr.map(a => {
              var newReportHazardExpList = {}
              
              let arr = []
             
              newDetectionReportJobExpList.hazardsExpList.map(z => {
                if (a == z.codeNo || a == z.hazardsCode) {
                  newReportHazardExpList = JSON.parse(JSON.stringify(z))
                  if(z.codeNo){
                    newReportHazardExpList.hazardsCode=z.codeNo
                  }
                  arr.push(z)
                }
              })

              newReportHazardExpList.newDetectionReportHazardsExpList = arr
              return newReportHazardExpList
            })
            return newDetectionReportJobExpList
          })
          
          return item
        })
        
        this.submitData(dataState, newReportWorkplaceExpList)
      } else {
        this.$refs['addForm'].validate(valid => {
          if (this.addForm.products.length == 0 && this.addForm.ifClassifiedUnit === false) {
            this.$message.error('需要填写产品信息')
          }
          if (valid) {
            this.$refs['endForm'].validate(valid => {
              if (valid) {
                if (this.deptExpList.length == 0) {
                  this.$system.notify('错误', '至少添加一个车间部门', 'error')
                  return
                }
                for (let i = 0; i < this.deptExpList.length; i++) {
                  if (this.deptExpList[i].jobExpList.length == 0) {
                    this.$system.notify('错误', '每个车间部门至少添加一个岗位工种', 'error')
                    return
                  }

                  for (let k = 0; k < this.deptExpList[i].jobExpList.length; k++) {
                    if (this.getPosiObjList(i, k).length == 0) {
                      this.$system.notify('错误', '每个岗位工种至少添加一个危害因素', 'error')
                      return
                    }
                    count += this.getPosiObjList(i, k).length
                    this.deptExpList[i].jobExpList[k].jobName = this.deptExpList[i].jobExpList[k].jobName
                    this.getPosiObjList(i, k).map((item, index) => {
                      if (!item.hazardsCode) {
                        item.hazardsCode = item.codeNo
                      }
                      if (!item.detectionItemCode) {
                        vaildo = false
                        this.$system.notify('错误', item.hazardsName+'要选择检测项目', 'error')
                        this.$set(item, 'warningText', '每个危害因素都要选择检测项目')
                      }
                    })
                  }
                }
                if (!vaildo) {
                  return
                }
                if (count > 100) {
                  this.$system.notify('错误', '危害因素总数不能超过100', 'error')
                  return
                }
                var newReportWorkplaceExpList = this.deptExpList.map(x => {
                  var item = {}
                  item.workplaceName = x.workplaceName
                  item.newDetectionReportJobExpList = x.jobExpList.map(y => {
                    var newDetectionReportJobExpList = {}
                    newDetectionReportJobExpList = JSON.parse(JSON.stringify(y))
                    var newArr = [...new Set(y.hazardsExpList.map(x => x.hazardsCode || x.codeNo))]
                    newDetectionReportJobExpList.newReportHazardExpList = newArr.map(a => {
                      var newReportHazardExpList = {}

                      let arr = []

                      newDetectionReportJobExpList.hazardsExpList.map(z => {
                        if (a == z.codeNo || a == z.hazardsCode) {
                          newReportHazardExpList = JSON.parse(JSON.stringify(z))
                          if(z.codeNo){
                            newReportHazardExpList.hazardsCode=z.codeNo
                          }
                          arr.push(z)
                        }
                      })
                      newReportHazardExpList.newDetectionReportHazardsExpList = arr
                      return newReportHazardExpList
                    })
                    return newDetectionReportJobExpList
                  })
                  return item
                })
                this.submitData(dataState, newReportWorkplaceExpList)
              }
            })
          }
        })
      }
    },
    submitData(dataState, newReportWorkplaceExpList) {
      var data = {
        dataState: dataState,
        employerName: this.addForm.employerName,
        employerCreditCode: this.addForm.creditCode,
        bizZone: this.addForm.bizZone,
        detectionType: this.addForm.detectionType,
        reportDate: this.addForm.reportDate,
        ifClassifiedUnit: this.addForm.ifClassifiedUnit,
        reportAnnexExpList1: this.addForm.annexList1,
        detectionProductExpList: this.addForm.products.map(i => {
          return {
            productName: i.productName,
            annualOutput: i.annualOutput,
            unit: i.unit,
          }
        }),
        reportCode: this.addForm.reportCode,
        newReportWorkplaceExpList,
        detectionConclusion: this.endForm.detectionConclusion,
        reportSuggest: this.endForm.reportSuggest,
      }
      if (this.addForm.uuid) {
        data.uuid = this.addForm.uuid
      }

      var postData = data
      this.$system.postJson(
        this.api + '/workplace/detection/addOrModifyDetectionReport2-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            if (postData.dataState == 0) {
              this.$system.notify('成功', data.mess, 'success')
              this.addForm.uuid = data.uuid
            } else {
              this.$system.notify('成功', data.mess, 'success')
              this.$router.push({ name: 'DetectionReportManagement' })
            }
            //  this.$refs.msPositive1.submit(data.annexUploadUid)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        {}
      )
    },
    unitAutocompleteQuery: function(queryString, cb) {
      var unitSimpleCodeList = this.unitOptionList
      var results = queryString ? unitSimpleCodeList.filter(this.unitAutocompleteFilter(queryString)) : unitSimpleCodeList
      cb(results)
    },
    /**
     * 计量单位过滤器
     */
    unitAutocompleteFilter: function(queryString) {
      return function(obj) {
        return obj.label.indexOf(queryString) === 0
      }
    },
    saveSuccess(data, ifNext) {
      if (data.type === '00') {
        this.$system.notify('成功', '保存成功', 'success')
        // if (ifNext) {
        //   let fkBySelfInspectionId = data.riskClassificationEvaluation.fkBySelfInspectionId && data.riskClassificationEvaluation.fkBySelfInspectionId.rid
        //   this.$router.push({ name: 'RiskBasicInformationEdit3', params: { fkBySelfInspectionId, rid: this.rid, workers: this.workers, staffNumberMap: data.staffNumberMap, harmsText: data.harmsText, employerUuid: data.riskClassificationEvaluation.employerUuid } })
        // }
        if (ifNext) {
          this.$router.push({ name: 'DetectionReportConclusion', params: { rid: this.rid, employerUuid: this.employerUuid } })
        }
        this.$emit('loading', false)
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
      }
      this.$emit('loading', false)
    },
    close(ifContinuation) {
      if (!ifContinuation) this.$refs['departmentDialog'].show(false)
      this.$nextTick(() => {
        this.msForm = {}
        if (this.$refs['msForm']) {
          this.$refs['msForm'].resetFields()
        }
      })
    },
    deletionPositive1(index) {
      let fileName = this.addForm.annexList1[index].fileName
      this.addForm.annexList1.splice(index, 1)
      this.$refs.msPositive1.deleteIndex(index)
      let fileList = this.$refs.msPositive1.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive1.deleteIndex(j)
            fileList = this.$refs.msPositive1.showFileList || []
            continue
          }
        }
      }
    },
    // 上传检测报告 文件的UID
    generateFilesUid1() {
      this.$emit('loading', true)
      let data = {
        employerId: this.employerBasicId,
        folder: '/workplace_detection',
      }
      this.$system.get(this.api + '/systematic/getAnnexUploadUid-1', data, true, true, this.generateFilesUidSuccess1, {})
    },
    generateFilesUidSuccess1(data) {
      if (data.type === '00') {
        this.$refs.msPositive1.submit(data.annexUploadUid)
      } else {
        this.$emit('loading', false)
        this.$system.notify('错误', data.mess, 'error')
      }
    },
    closePost(ifContinuation) {
      if (!ifContinuation) {
        this.$refs['postDialog'].show(false)
        this.$nextTick(() => {
          this.postForm = {}
          if (this.$refs['postForm']) {
            this.$refs['postForm'].resetFields()
          }
        })
      }
    },
    harmClose() {
      this.$set(this, 'searchInput3', '')
      this.$refs['harmDialog'].show(false)
    },
    getPosiObjList(index, key, list) {
      if (list) {
        list.map(item => {
          if (item.hazardNature) {
            item.hazardNature = parseInt(item.hazardNature)
            item.hazardNatureOld = parseInt(item.hazardNature)
          }
          return { ...item }
        })
        this.deptExpList[index].jobExpList[key].hazardsExpList = this.deptExpList[index].jobExpList[key].hazardsExpList.concat(list)
      }
      if (key != undefined) {
        return this.deptExpList[index].jobExpList[key].hazardsExpList
      } else {
        return this.deptExpList[index].jobExpList
      }
    },
    vaildHarm(item) {
      return (item.valueTwa == undefined && item.valueStel == undefined && item.valueMe == undefined && item.valuePe == undefined && item.otherValue == undefined) || (item.valueTwa == '' && item.valueStel == '') || (item.valueTwa == '' && item.valueMe == '') || (item.valueTwa == '' && item.valuePe == '') || (item.valueStel == '' && item.valueMe == '') || (item.valueStel == '' && item.valuePe == '') || (item.valueMe == '' && item.valuePe == '') || (item.valueTwa == '' && item.valueStel == undefined && item.valueMe == undefined && item.valuePe == undefined) || (item.valueTwa == undefined && item.valueStel == '' && item.valueMe == undefined && item.valuePe == undefined) || (item.valueTwa == undefined && item.valueStel == undefined && item.valueMe == '' && item.valuePe == undefined) || (item.valueTwa == undefined && item.valueStel == undefined && item.valueMe == undefined && item.valuePe == '')
    },
    closeHarm() {
      this.showOhsSuspectedCodeNoList = []
      this.$set(this, 'searchInput3', '')
      this.$refs['harmDialog'].show(false)
    },
    back() {
      this.save(false, true)
      // this.$router.go(-1)
      this.$router.push({ name: 'RiskBasicInformationEdit1', params: { employerUuid: this.employerUuid, rid: this.rid } })
    },
    //报告编号查重
    reportCodeIfExist(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        let data = {
          uuid: this.addForm.uuid,
          reportCode: this.addForm.reportCode, //检测报告编码
        }
        this.$system.postJson(this.api + '/workplace/detection/getReportCodeIfExist-1', data, true, true, data => {
          if (data.type === '00') {
            if (data.ifExist) {
              return callback(new Error('报告编号重复'))
            } else {
              return callback()
            }
          } else {
            return callback(new Error(data.mess))
          }
        })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.zwx-table {
  /deep/ .cell {
    padding: 10px 14px !important;
  }
}

/deep/ .el-input-group {
  vertical-align: baseline !important;
}

/deep/ .el-input-group__prepend {
  width: 50px !important;
  vertical-align: baseline !important;
  border: none !important;

  // background-color: red;
  .el-select {
    .el-input--suffix {
      position: relative !important;
      top: 3px !important;
    }
  }
}

.el-input-group__prepend {
  border: none !important;
}

.Touch-body {
  margin-bottom: 50px;
  padding: 12px 16px;
  //box-shadow: 0px -1px 0px 0px #e1e5f4 inset;
}

.harmForm {
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }

  /deep/ .ms-input {
    width: 120px !important;
  }

  /deep/ .el-input__inner {
    width: 120px !important;
  }

  /deep/.ms-select-simple-width .el-input__inner {
    width: 120px !important;
  }

  .warning {
    margin-left: 20px;
    color: red;
  }

  .danger {
    color: #dd3838 !important;
  }

  .tag {
    margin-left: 10px;
    display: inline-block;
    width: 32px;
    height: 18px;
    border: 1px dashed;
    border-radius: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
  }

  .tag0 {
    border-color: #fad7d7;
    background: #fce8e8;
    color: #e75d55;
  }

  .tag1 {
    border-color: #d2eaf7;
    background: #e8f5fc;
    color: #4276f0;
  }

  /deep/ .el-icon-arrow-up {
    display: none;
  }

  /*/deep/.el-select{
      width:10px;
    }*/
  /deep/ .el-input-group__prepend {
    .el-select {
      width: 10px;
    }

    .el-input__inner {
      max-width: 40px;
      padding: 0px 0px 0px 15px;
    }
  }

  //将
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
  }
}

.remind {
  padding: 0 12px;
  margin-bottom: 16px;
  width: 100%;
  height: 30px;
  background: #f4f9ff;
  border: 1px solid #b4ceef;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #004bc7;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.hazard {
  // margin-top: 10px;

  &-title {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    background: #f6f8fb;
    box-shadow: 0px 1px 0px 0px #e1e5f4 inset, 0px 0px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset;
    font-size: 14px;
    font-weight: 500;
    color: #505b89;

    & i:first-child {
      cursor: pointer;
      color: #b0bfe6;
    }

    & i:nth-child(n + 2) {
      margin-right: 15px;
      color: #0037c7;
      cursor: pointer;
    }

    span {
      margin-right: 20px;
      padding: 0 10px;
    }
  }
}

.post {
  // &:nth-child(n + 2) {
  .hazard-title {
    box-shadow: 0px 0px 0px 0px #e1e5f4 inset, 0px 0px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset !important;
  }

  // }
  // box-shadow: 0px 1px 0px 0px #e1e5f4 inset, 0px -1px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset;
  /deep/ .el-table {
    border-left: 1px solid #e1e5f4 !important;
  }

  &-title {
    padding-left: 40px;
    background: #ffffff;

    & i:first-child {
      width: 6px;
      height: 6px;
      background: #316cf5;
      border-radius: 50%;
    }

    span {
      padding-left: 5px;
      margin-right: 5px;
    }

    .splice {
      margin-right: 10px;
      width: 2px;
      height: 12px;
      background: #e1e5f4;
    }
  }
}

.continuation {
  padding-left: 20px;
  color: #0037c7;
  cursor: pointer;

  i {
    margin-right: 4px;
  }
}

/deep/ .el-dialog {
  width: 27% !important;
  min-width: 500px !important;
}

.submit {
  padding-left: 20px;
  position: fixed;
  bottom: 10px;
  width: calc(100% - 24px);
  height: 56px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 1px 0px 0px #d5d9e0 inset;
  display: flex;
  align-items: center;
  z-index: 22;
}

.simp {
  /deep/ .ms-input {
    width: 270px !important;
  }

  /deep/ .el-input__inner {
    width: 270px !important;
  }
}

.width90 {
  /deep/ .ms-input {
    width: 270px !important;
  }

  /deep/ .el-input__inner {
    width: 270px !important;
  }
}

.detection-place {
  /deep/.el-popover__reference {
    width: 270px !important;
  }
}
</style>
<style lang="less">
.ms-select-popper .el-select-dropdown__list {
  min-width: 130px !important;
}
</style>
<style lang="less" scoped>
.msattachment {
  margin-right: 12px;
  color: rgb(49, 108, 245) !important;
  cursor: pointer;
}

.risk-evaluation-edit-2 {
  /deep/.el-form-item {
    margin-bottom: 15px !important;
  }
}

/deep/ .zwxkey {
  font-family: 'Harmony Regular', sans-serif !important;
}

.ssas {
  /deep/ .el-dialog {
    width: 676px !important;
    min-width: 676px !important;
  }
}

.dept-body {
  margin-bottom: 50px;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px #e1e5f4 inset;
}

.harmForm {
  /deep/ .el-form-item {
    margin-bottom: 0px !important;
  }

  /deep/ .zwx-table tr td:last-child {
    // padding-bottom: 16px !important;
    border-right: 1px solid #e1e5f4 !important;
  }

  /deep/ .zwx-table .el-table__fixed-right tr th:last-child {
    border-right: 1px solid #e1e5f4 !important;
  }

  /deep/ .zwx-table .el-form-item .zwx-select .el-input__inner {
    width: 120px !important;
  }

  /deep/ .zwx-table .el-form-item .zwx-select-simple-width .el-input__inner {
    width: 100% !important;
  }

  .warning {
    margin-left: 20px;
    color: red;
  }

  .tag {
    margin-left: 10px;
    display: inline-block;
    width: 32px;
    height: 18px;
    border: 1px dashed;
    border-radius: 2px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
  }

  .tag0 {
    border-color: #fad7d7;
    background: #fce8e8;
    color: #e75d55;
  }

  .tag1 {
    border-color: #d2eaf7;
    background: #e8f5fc;
    color: #4276f0;
  }
}

.remind {
  padding: 0 12px;
  margin-bottom: 2px;
  width: 100%;
  height: 30px;
  background: #f4f9ff;
  border: 1px solid #b4ceef;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #004bc7;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }
}

.department {
  // margin-top: 12px;
  // margin-bottom: 10px;
  &-title {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    background: #f6f8fb;
    box-shadow: 0px 1px 0px 0px #e1e5f4 inset, 0px 0px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset;
    font-size: 14px;
    font-weight: 500;
    color: #505b89;

    & i:first-child {
      cursor: pointer;
      color: #b0bfe6;
    }

    & i:nth-child(n + 2) {
      margin-right: 15px;
      color: #0037c7;
      cursor: pointer;
    }

    span {
      margin-right: 20px;
      padding: 0 10px;
    }
  }
}

.post {
  // box-shadow: 0px 1px 0px 0px #e1e5f4 inset, 0px -1px 0px 0px #e1e5f4 inset, 1px 0px 0px 0px #e1e5f4 inset, -1px 0px 0px 0px #e1e5f4 inset;
  /deep/ .el-table {
    border-left: 1px solid #e1e5f4 !important;
  }

  &-title {
    padding-left: 40px;
    background: #ffffff;

    & i:first-child {
      width: 6px;
      height: 6px;
      background: #316cf5;
      border-radius: 50%;
    }

    span {
      padding-left: 5px;
      margin-right: 5px;
    }

    .splice {
      margin-right: 10px;
      width: 2px;
      height: 12px;
      background: #e1e5f4;
    }
  }
}

.continuation {
  padding-left: 20px;
  color: #0037c7;
  cursor: pointer;

  i {
    margin-right: 4px;
  }
}

/deep/ .el-dialog {
  width: 27% !important;
  min-width: 500px !important;
}

.harm {
  /deep/ .el-dialog {
    width: 50% !important;
    min-width: 500px !important;
  }
}

.submit {
  padding-left: 20px;
  position: fixed;
  bottom: 10px;
  width: calc(100% - 24px);
  height: 56px;
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 1px 0px 0px #d5d9e0 inset;
  display: flex;
  align-items: center;
  z-index: 22;
}

// .ss {
//   /deep/ .zwx-input {
//     width: 270px !important;
//   }
// }
.simp {
  /deep/ .zwx-input {
    width: 270px !important;
  }

  /deep/ .el-input__inner {
    width: 270px !important;
  }
}

.width90 {
  /deep/ .zwx-input {
    width: 270px !important;
  }

  /deep/ .el-input__inner {
    width: 270px !important;
  }
}

.hazardFactor-list2 {
  //左侧部分
  width: 300px;
  height: 500px;
  border-right: 1px solid #e6e6e6;

  .hazardFactor-search {
    padding: 12px 0px 12px 12px;
    border-bottom: 1px solid #e6e6e6;
  }

  .name-hidden {
    white-space: nowrap;
    width: 220px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: flex;
    justify-content: space-between;

    & span:first-child {
      width: 98%;
      text-align: left;
    }

    .hatext {
      width: 60% !important;
      text-align: left !important;
      overflow: hidden;
      white-space: nowrap; //内容超出不换行
      text-overflow: ellipsis;
    }

    & span:last-child {
      width: 50%;
      text-align: right;
    }
  }
}

.equipment-detail2 {
  width: calc(100% - 300px);
  height: 500px;

  .header-title {
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: left;
    color: #1f2d3d;
    line-height: 14px;
  }
}

.unit {
  display: inline-flex;
  width: 34px;
  height: 32px;
  background: #eaeff5;
  border: 1px solid #a8bbcf;
  border-radius: 0px 2px 2px 0px;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-left: -1px;
  font-size: 14px;
  color: #1f2d3d;
  line-height: 14px;
}

.zwx-table td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.zwx-select2 {
  /deep/ .el-input__inner:nth-child(1) {
    padding-left: 6px !important;
    font-size: 20px !important;
  }

  /deep/ .el-input__suffix:nth-last-child(1) {
    right: 6px !important;

    i {
      font-size: 10px !important;
    }
  }

  .el-input-group__prepend {
    width: 100px !important;

    /deep/ .el-input--suffix {
      position: relative !important;
      top: 2px !important;
    }
  }
}

.transition-box:nth-child(1) {
  margin-top: 0px !important;
}
.ms-el-message {
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 236px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  padding: 0px 10px 0px 9px;
  border-radius: 2px;
}

.ms-el-message--warning {
  background-color: #fff5e6;
  border-color: #ffa738;
  color: #ff9400;
}
.uni-sel {
  /deep/ .el-icon-search {
    margin-top: -10px !important;
  }

  /deep/ .el-icon-delete {
    margin-top: -10px !important;
  }
}

.topTitle {
  /deep/ .zwx-head-title {
    border-top: none !important;
  }
}

.select-component {
  /deep/ .zwx-input {
    width: 300px !important;
    cursor: pointer;
  }

  /deep/ .el-input__suffix {
    right: 6px !important;
  }

  /deep/ .el-input__inner {
    width: 300px;
  }

  /deep/ .zwx-input .el-input__suffix .el-input__suffix-inner .el-input__icon {
    margin-top: 0;
    line-height: 32px !important;
  }
}
</style>
