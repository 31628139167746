var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "zwx-dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              key: "msForm",
              ref: "msForm",
              staticClass: "ms-form",
              attrs: {
                model: _vm.msForm,
                rules: _vm.rules,
                "label-position": "right",
                "label-width": "150px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用场景：", prop: "appScenario" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.msForm.appScenario,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "appScenario", $$v)
                        },
                        expression: "msForm.appScenario"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 0 } },
                        [_vm._v("通用开票")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("个性开票")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票类型：", prop: "businessType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        multiple: false,
                        "collapse-tags": "",
                        placeholder: "请选择",
                        clearable: ""
                      },
                      model: {
                        value: _vm.msForm.businessType,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "businessType", $$v)
                        },
                        expression: "msForm.businessType"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "用友开票", value: 1 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配置名称：", prop: "invoiceDescribe" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceDescribe,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceDescribe",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceDescribe"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "环境地址：", prop: "invoiceEnv" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "200"
                    },
                    model: {
                      value: _vm.msForm.invoiceEnv,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceEnv",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceEnv"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "开票申请API地址：", prop: "invoiceApiUrl" }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "200"
                    },
                    model: {
                      value: _vm.msForm.invoiceApiUrl,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceApiUrl",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceApiUrl"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APPKEY：", prop: "invoiceAppKey" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceAppKey,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceAppKey",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceAppKey"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP密钥：", prop: "invoiceAppSecret" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceAppSecret,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceAppSecret",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceAppSecret"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票保存地址：", prop: "invoicePdfPath" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "200"
                    },
                    model: {
                      value: _vm.msForm.invoicePdfPath,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoicePdfPath",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoicePdfPath"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "纳税人识别号：",
                    prop: "invoiceSellerCreditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceSellerCreditCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceSellerCreditCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceSellerCreditCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品税率：", prop: "invoiceTaxRate" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "20"
                    },
                    model: {
                      value: _vm.msForm.invoiceTaxRate,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceTaxRate",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceTaxRate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "是否无需审核：", prop: "invoiceAutoAudit" }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.msForm.invoiceAutoAudit,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "invoiceAutoAudit", $$v)
                        },
                        expression: "msForm.invoiceAutoAudit"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("直接开票")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("需要审核")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "电子税务局用户名：",
                    prop: "invoiceAllelcUsername"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceAllelcUsername,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceAllelcUsername",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceAllelcUsername"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "电子税务局密码：",
                    prop: "invoiceAllelcPassword"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "100% !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.invoiceAllelcPassword,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "invoiceAllelcPassword",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.invoiceAllelcPassword"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "ms-dialog-footer-specical",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.close()
                }
              }
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.commit()
                }
              }
            },
            [_vm._v("确定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }