var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Touch" },
    [
      _c("base-headline", {
        staticClass: "topTitle",
        attrs: { title: "基本信息" }
      }),
      _c(
        "el-form",
        {
          key: "addForm",
          ref: "addForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules1,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "单位名称：",
                    prop: "employerName"
                  }
                },
                [
                  _c("unit-select", {
                    ref: "unitSelectRef",
                    staticClass: "uni-sel",
                    attrs: {
                      isSms4: true,
                      width: "300px",
                      placeholderText: "请输入5个字符搜索",
                      isPost: true,
                      requestUrl: _vm.requestUrl,
                      otherRequestUrl:
                        "/data/exchange/getUnitListFromEnterprise-0",
                      ifShowMoreBtn: true,
                      ifNeedMore: true
                    },
                    on: {
                      unitNameSearch: _vm.unitNameSearch,
                      unitNameSearchOrDel: _vm.unitNameDel,
                      change: _vm.unitNameSelect,
                      loading: data => _vm.$emit("loading", data)
                    },
                    model: {
                      value: _vm.addForm.employerName,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "employerName", $$v)
                      },
                      expression: "addForm.employerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "社会信用代码：",
                    prop: "creditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入社会信用代码",
                      clearable: "",
                      disabled: _vm.creditCodeDisabled
                    },
                    model: {
                      value: _vm.addForm.creditCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "creditCode", $$v)
                      },
                      expression: "addForm.creditCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "行政区划：",
                    prop: "bizZone"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "zoneCodeArea",
                    staticClass: "select-component",
                    attrs: {
                      placeholder: "请选择",
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true
                    },
                    on: { change: _vm.nativePlaceChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "检测类型：",
                    prop: "detectionType"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "260px !important" },
                      model: {
                        value: _vm.addForm.detectionType,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "detectionType", $$v)
                        },
                        expression: "addForm.detectionType"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("定期检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 3 } },
                        [_vm._v("监督检测")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 2 } },
                        [_vm._v("现状评价")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告日期：",
                    prop: "reportDate"
                  }
                },
                [
                  _c("el-date-picker", {
                    staticClass: "zwx-date-picker",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      "popper-class": "zwx-date-picker-popper",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "",
                      "picker-options": _vm.$validate.noOverToday()
                    },
                    model: {
                      value: _vm.addForm.reportDate,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reportDate", $$v)
                      },
                      expression: "addForm.reportDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "报告编号：",
                    prop: "reportCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入报告编号",
                      clearable: "",
                      maxlength: "100"
                    },
                    model: {
                      value: _vm.addForm.reportCode,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "reportCode", $$v)
                      },
                      expression: "addForm.reportCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c("attachmen-upload", {
                attrs: {
                  labelwidth: "110px",
                  folder: "/workplace_detection",
                  label: "报告附件：",
                  accept: ".pdf,.PDF",
                  limit: 5,
                  fileProp: "annexList1"
                },
                model: {
                  value: _vm.addForm.annexList1,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "annexList1", $$v)
                  },
                  expression: "addForm.annexList1"
                }
              })
            ],
            1
          ),
          _c(
            "base-headline",
            { attrs: { title: "主要产品" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: {
                    icon: "el-icon-plus",
                    disabled: _vm.addForm.ifClassifiedUnit
                  },
                  on: { click: _vm.addProduct }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "14px" },
                  model: {
                    value: _vm.addForm.ifClassifiedUnit,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "ifClassifiedUnit", $$v)
                    },
                    expression: "addForm.ifClassifiedUnit"
                  }
                },
                [_vm._v("军工等涉密企业")]
              ),
              _c(
                "div",
                {
                  staticClass: "ms-el-message ms-el-message--warning",
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。 "
                  )
                ]
              )
            ],
            1
          ),
          !_vm.addForm.ifClassifiedUnit
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "zwx-table",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.addForm.products,
                        border: "",
                        stripe: ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "index", label: "序号", width: "80" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _vm._v(" " + _vm._s(scope.$index + 1) + " ")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3056706777
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "产品名称",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." +
                                          scope.$index +
                                          ".productName",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请输入产品名称",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        staticStyle: {
                                          width: "200px !important"
                                        },
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "50"
                                        },
                                        model: {
                                          value: scope.row.productName,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "productName",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.productName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4002050973
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "年产量",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." +
                                          scope.$index +
                                          ".annualOutput",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请输入年产量",
                                            trigger: "blur"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        staticStyle: {
                                          width: "200px !important"
                                        },
                                        attrs: {
                                          oninput:
                                            "value=value.replace(/[^0-9.]/g, '')",
                                          placeholder: "请输入数字",
                                          maxlength: "12"
                                        },
                                        model: {
                                          value: scope.row.annualOutput,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "annualOutput",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.annualOutput"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          782318301
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "计量单位",
                          width: 240,
                          "header-align": "center",
                          align: "center"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "products." + scope.$index + ".unit",
                                        rules: [
                                          {
                                            required: !_vm.addForm
                                              .ifClassifiedUnit,
                                            message: "请选择单位",
                                            trigger: "change"
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c("el-autocomplete", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          "value-key": "label",
                                          "fetch-suggestions":
                                            _vm.unitAutocompleteQuery,
                                          maxlength: 10,
                                          clearable: ""
                                        },
                                        model: {
                                          value: scope.row.unit,
                                          callback: function($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "unit",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "scope.row.unit"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          19344063
                        )
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "red" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.removeRow(scope.$index)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3328054818
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("base-headline", {
        staticClass: "topTitle",
        attrs: { title: "检测信息" }
      }),
      _c(
        "div",
        { staticClass: "Touch-body" },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "400" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "zwx-button zwx-button-icontext-28",
                    staticStyle: { "margin-left": "10px" },
                    attrs: { icon: "el-icon-plus" },
                    on: {
                      click: function($event) {
                        return _vm.addDepartment()
                      }
                    }
                  },
                  [_vm._v("添加")]
                )
              ],
              1
            ),
            _vm._m(0)
          ]),
          _vm._l(_vm.deptExpList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "department",
                staticStyle: { "margin-top": "12px" }
              },
              [
                _c("div", { staticClass: "department-title" }, [
                  _c("i", {
                    staticClass: "el-icon-caret-bottom",
                    style: `transform:rotate(${item.ifExpand ? -90 : 0}deg);`,
                    on: {
                      click: function($event) {
                        return _vm.expand(item)
                      }
                    }
                  }),
                  _c("span", [_vm._v(_vm._s(item.workplaceName))]),
                  _c("i", {
                    staticClass: "el-icon-plus",
                    on: {
                      click: function($event) {
                        return _vm.addPost(index)
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "el-icon-edit",
                    on: {
                      click: function($event) {
                        return _vm.addDepartment(index)
                      }
                    }
                  }),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function($event) {
                        return _vm.removeDepartment(index)
                      }
                    }
                  })
                ]),
                _vm._l(item.jobExpList, function(val, key) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !item.ifExpand,
                          expression: "!item.ifExpand"
                        }
                      ],
                      key: key,
                      staticClass: "department post transition-box"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "department-title post-title" },
                        [
                          _c("i"),
                          _c("span", [_vm._v(_vm._s(val.jobName))]),
                          _c("span", { staticStyle: { color: "#191919" } }, [
                            _vm._v(_vm._s(val.staffNumber) + "人")
                          ]),
                          _c("div", { staticClass: "splice" }),
                          _c("span", { staticStyle: { color: "#191919" } }, [
                            _vm._v(
                              "每班接触时长" + _vm._s(val.exposureHd) + "(h/d)"
                            )
                          ]),
                          _c("div", { staticClass: "splice" }),
                          _c("span", { staticStyle: { color: "#191919" } }, [
                            _vm._v(
                              "每周接触天数" + _vm._s(val.exposureDw) + "(d/w)"
                            )
                          ]),
                          _c("div", { staticClass: "splice" }),
                          _c("i", {
                            staticClass: "el-icon-plus",
                            on: {
                              click: function($event) {
                                return _vm.addHarm(index, key)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-edit",
                            on: {
                              click: function($event) {
                                return _vm.addPost(index, key)
                              }
                            }
                          }),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            on: {
                              click: function($event) {
                                return _vm.removePost(index, key)
                              }
                            }
                          })
                        ]
                      ),
                      _c(
                        "el-form",
                        {
                          ref: "harmForm",
                          refInFor: true,
                          staticClass: "harmForm",
                          attrs: {
                            inline: true,
                            model: val,
                            "label-position": "right",
                            rules: _vm.harmRules
                          }
                        },
                        [
                          _c(
                            "el-table",
                            {
                              key: _vm.tabRef,
                              staticClass: "zwx-table",
                              staticStyle: { "min-width": "1200px" },
                              attrs: {
                                "row-key": _vm.getRid(),
                                data: val.hazardsExpList,
                                border: "",
                                stripe: ""
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "hazardsName",
                                  label: "职业病危害因素",
                                  width: 190,
                                  "header-align": "center",
                                  align: "left"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ row }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.hazardsName != null
                                                  ? row.hazardsName
                                                  : "--"
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "检测项目",
                                  width: 140,
                                  "header-align": "center",
                                  align: "left"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ row }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.validLine(
                                                  row.detectionItemName
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "checkPointName",
                                  label: "检测点位",
                                  width: 190,
                                  "header-align": "center",
                                  align: "left"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ row }) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                row.checkPointName != null
                                                  ? row.checkPointName
                                                  : "--"
                                              )
                                            )
                                          ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    label: "检测结果",
                                    "header-align": "center"
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "CTWA",
                                      width: 120,
                                      "header-align": "center",
                                      align: "center",
                                      "render-header": _vm.renderHeaderLabel
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  row.tagTwa == 2 ? "<" : ""
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.validLine(row.valueTwa)
                                                  )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "CSTE",
                                      width: 120,
                                      "header-align": "center",
                                      align: "center",
                                      "render-header": _vm.renderHeaderLabel
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    row.tagStel == 2 ? "<" : ""
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.validLine(
                                                        row.valueStel
                                                      )
                                                    )
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "CME",
                                      width: 120,
                                      "header-align": "center",
                                      align: "center",
                                      "render-header": _vm.renderHeaderLabel
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  row.tagMe == 2 ? "<" : ""
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.validLine(row.valueMe)
                                                  )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "CPE",
                                      width: 120,
                                      "header-align": "center",
                                      align: "center",
                                      "render-header": _vm.renderHeaderLabel
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  row.tagPe == 2 ? "<" : ""
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.validLine(row.valuePe)
                                                  )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "噪声等效声级",
                                      width: 140,
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.validLine(row.noiseLex)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "其他因素",
                                      width: 140,
                                      "header-align": "center",
                                      align: "center"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function({ row }) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.validLine(
                                                      row.otherValue
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              ),
                              _c("el-table-column", {
                                attrs: {
                                  label: "判定结果",
                                  width: 160,
                                  "header-align": "center",
                                  align: "center"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function({ row }) {
                                        return [
                                          row.judgementResult === "" ||
                                          row.judgementResult === undefined
                                            ? _c("span", [_vm._v(_vm._s("--"))])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    row.judgementResult
                                                      ? "合格"
                                                      : "超标"
                                                  )
                                                )
                                              ])
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  "header-align": "center",
                                  align: "left",
                                  "min-width": 150
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.editHarm(
                                                    scope,
                                                    index,
                                                    key
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("编辑")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              staticClass:
                                                "zwx-button zwx-button-text-26",
                                              attrs: { type: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteItem(
                                                    scope,
                                                    index,
                                                    key
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("删除")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "warning" },
                                            [
                                              _vm._v(
                                                _vm._s(scope.row.warningText)
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          })
        ],
        2
      ),
      _c("base-headline", { attrs: { title: "检测结论及建议" } }),
      _c(
        "el-form",
        {
          key: "endForm",
          ref: "endForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.endForm,
            rules: _vm.endRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "112px",
                    label: "检测结论：",
                    prop: "detectionConclusion"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "888px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      resize: "none",
                      placeholder: "请输入",
                      maxlength: "500",
                      clearable: ""
                    },
                    model: {
                      value: _vm.endForm.detectionConclusion,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.endForm,
                          "detectionConclusion",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "endForm.detectionConclusion"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "112px",
                    label: "建议：",
                    prop: "reportSuggest"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-textarea",
                    staticStyle: { width: "888px !important" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4 },
                      resize: "none",
                      placeholder: "请输入",
                      maxlength: "500",
                      clearable: ""
                    },
                    model: {
                      value: _vm.endForm.reportSuggest,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.endForm,
                          "reportSuggest",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "endForm.reportSuggest"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "base-dialog",
        {
          ref: "editDialog",
          staticClass: "edit-dialog ssas",
          attrs: { title: "编辑" },
          on: {
            determine: _vm.addHarzForm,
            cancel: _vm.closeHarz,
            close: _vm.closeHarz
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "harzForm",
              staticClass: "harzinput",
              staticStyle: { "padding-top": "20px" },
              attrs: {
                "label-width": "140px",
                inline: true,
                "label-position": "right",
                model: _vm.harzForm,
                rules: _vm.harzRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "140px", label: "危害因素：" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "line-height": "20px",
                        width: "160px",
                        height: "40px",
                        "align-items": "center"
                      }
                    },
                    [_vm._v(_vm._s(_vm.harzForm.hazardsName))]
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "140px",
                    label: "检测项目：",
                    prop: "detectionItemName"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select ms-select-simple-width-200",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        placeholder: "请选择",
                        filterable: true
                      },
                      on: { change: _vm.changeHarz },
                      model: {
                        value: _vm.harzForm.detectionItemName,
                        callback: function($$v) {
                          _vm.$set(_vm.harzForm, "detectionItemName", $$v)
                        },
                        expression: "harzForm.detectionItemName"
                      }
                    },
                    _vm._l(
                      _vm.harmMap[
                        _vm.harzForm.hazardsCode || _vm.harzForm.codeNo
                      ],
                      function(item) {
                        return _c("el-option", {
                          key: item.codeNo,
                          attrs: { label: item.itemName, value: item.itemName }
                        })
                      }
                    ),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "140px",
                    label: "检测点位：",
                    prop: "checkPointName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input ms-select-simple-width-200",
                    attrs: { clearable: "", maxlength: "50" },
                    model: {
                      value: _vm.harzForm.checkPointName,
                      callback: function($$v) {
                        _vm.$set(_vm.harzForm, "checkPointName", $$v)
                      },
                      expression: "harzForm.checkPointName"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-bottom": "20px" } },
                [
                  _c(
                    "base-headline",
                    { attrs: { title: "职业病危害因素接触情况" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#F56C6C",
                            "font-size": "12px",
                            "margin-left": "15px"
                          }
                        },
                        [_vm._v(_vm._s(_vm.warningResult))]
                      )
                    ]
                  )
                ],
                1
              ),
              !(
                _vm.harzForm.codeNo == "130001" ||
                _vm.harzForm.hazardsCode == "130001"
              )
                ? _c(
                    "div",
                    [
                      !_vm.$zwxBase.verifyIsBlank(_vm.selectProject.itemStdTwa)
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "padding-bottom": "5px" },
                              attrs: {
                                "label-width": "140px",
                                prop: "valueTwa"
                              }
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" C "),
                                _c("sub", [_vm._v("TWA")]),
                                _vm._v(" ： ")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "zwx-input zwx-select2",
                                  attrs: {
                                    placeholder: "请输入内容",
                                    "auto-complete": "off",
                                    controls: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.$validate.number(
                                        _vm.harzForm,
                                        "valueTwa",
                                        4,
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.harzForm.valueTwa,
                                    callback: function($$v) {
                                      _vm.$set(_vm.harzForm, "valueTwa", $$v)
                                    },
                                    expression: "harzForm.valueTwa"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        slot: "prepend",
                                        "popper-class": "zwx-select-popper",
                                        placeholder: ""
                                      },
                                      on: {
                                        change: e =>
                                          _vm.deepChange(
                                            e,
                                            _vm.harzForm,
                                            "tagTwa"
                                          )
                                      },
                                      slot: "prepend",
                                      model: {
                                        value: _vm.harzForm.tagTwa,
                                        callback: function($$v) {
                                          _vm.$set(_vm.harzForm, "tagTwa", $$v)
                                        },
                                        expression: "harzForm.tagTwa"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "=", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "<", value: 2 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      !_vm.$zwxBase.verifyIsBlank(_vm.selectProject.itemStdStel)
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "padding-bottom": "5px" },
                              attrs: {
                                "label-width": "142px",
                                prop: "valueStel"
                              }
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" C "),
                                _c("sub", [_vm._v("STE")]),
                                _vm._v(" ： ")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "zwx-input zwx-select2",
                                  attrs: {
                                    placeholder: "请输入内容",
                                    "auto-complete": "off",
                                    controls: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.$validate.number(
                                        _vm.harzForm,
                                        "valueStel",
                                        4,
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.harzForm.valueStel,
                                    callback: function($$v) {
                                      _vm.$set(_vm.harzForm, "valueStel", $$v)
                                    },
                                    expression: "harzForm.valueStel"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        slot: "prepend",
                                        placeholder: ""
                                      },
                                      on: {
                                        change: e =>
                                          _vm.deepChange(
                                            e,
                                            _vm.harzForm,
                                            "tagStel"
                                          )
                                      },
                                      slot: "prepend",
                                      model: {
                                        value: _vm.harzForm.tagStel,
                                        callback: function($$v) {
                                          _vm.$set(_vm.harzForm, "tagStel", $$v)
                                        },
                                        expression: "harzForm.tagStel"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "=", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "<", value: 2 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      !_vm.$zwxBase.verifyIsBlank(_vm.selectProject.itemStdMac)
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "padding-bottom": "5px" },
                              attrs: { "label-width": "142px", prop: "valueMe" }
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" C "),
                                _c("sub", [_vm._v("ME")]),
                                _vm._v(" ： ")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "zwx-input zwx-select2",
                                  attrs: {
                                    placeholder: "请输入内容",
                                    "auto-complete": "off",
                                    controls: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.$validate.number(
                                        _vm.harzForm,
                                        "valueMe",
                                        4,
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.harzForm.valueMe,
                                    callback: function($$v) {
                                      _vm.$set(_vm.harzForm, "valueMe", $$v)
                                    },
                                    expression: "harzForm.valueMe"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        slot: "prepend",
                                        placeholder: ""
                                      },
                                      on: {
                                        change: e =>
                                          _vm.deepChange(
                                            e,
                                            _vm.harzForm,
                                            "tagMe"
                                          )
                                      },
                                      slot: "prepend",
                                      model: {
                                        value: _vm.harzForm.tagMe,
                                        callback: function($$v) {
                                          _vm.$set(_vm.harzForm, "tagMe", $$v)
                                        },
                                        expression: "harzForm.tagMe"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "=", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "<", value: 2 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      !(
                        _vm.$zwxBase.verifyIsBlank(
                          _vm.selectProject.itemStdTwa
                        ) ||
                        !_vm.$zwxBase.verifyIsBlank(
                          _vm.selectProject.itemStdStel
                        )
                      )
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { "padding-bottom": "5px" },
                              attrs: { "label-width": "140px", prop: "valuePe" }
                            },
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v(" C "),
                                _c("sub", [_vm._v("PE")]),
                                _vm._v(" ： ")
                              ]),
                              _c(
                                "el-input",
                                {
                                  staticClass: "zwx-input zwx-select2",
                                  attrs: {
                                    placeholder: "请输入内容",
                                    "auto-complete": "off",
                                    controls: false
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.$validate.number(
                                        _vm.harzForm,
                                        "valuePe",
                                        4,
                                        5
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.harzForm.valuePe,
                                    callback: function($$v) {
                                      _vm.$set(_vm.harzForm, "valuePe", $$v)
                                    },
                                    expression: "harzForm.valuePe"
                                  }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        slot: "prepend",
                                        placeholder: ""
                                      },
                                      on: {
                                        change: e =>
                                          _vm.deepChange(
                                            e,
                                            _vm.harzForm,
                                            "tagPe"
                                          )
                                      },
                                      slot: "prepend",
                                      model: {
                                        value: _vm.harzForm.tagPe,
                                        callback: function($$v) {
                                          _vm.$set(_vm.harzForm, "tagPe", $$v)
                                        },
                                        expression: "harzForm.tagPe"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "=", value: 1 }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "<", value: 2 }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { "padding-bottom": "5px" },
                          attrs: {
                            "label-width": "140px",
                            label: "噪声声效等级：",
                            prop: "noiseLex"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-input",
                            attrs: {
                              placeholder: "请输入内容",
                              "auto-complete": "off",
                              controls: false
                            },
                            on: {
                              input: function($event) {
                                return _vm.$validate.number(
                                  _vm.harzForm,
                                  "noiseLex",
                                  4,
                                  2
                                )
                              }
                            },
                            model: {
                              value: _vm.harzForm.noiseLex,
                              callback: function($$v) {
                                _vm.$set(_vm.harzForm, "noiseLex", $$v)
                              },
                              expression: "harzForm.noiseLex"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "padding-bottom": "5px" },
                      attrs: {
                        "label-width": "140px",
                        label: "其他因素：",
                        prop: "otherValue"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "zwx-input",
                        attrs: { "auto-complete": "off", maxlength: "50" },
                        model: {
                          value: _vm.harzForm.otherValue,
                          callback: function($$v) {
                            _vm.$set(_vm.harzForm, "otherValue", $$v)
                          },
                          expression: "harzForm.otherValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "padding-bottom": "5px" },
                      attrs: {
                        "label-width": "140px",
                        label: "判定结果：",
                        prop: "judgementResult"
                      }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "zwx-radio-group",
                          on: {
                            input: i =>
                              _vm.deepChange(i, _vm.harzForm, "judgementResult")
                          },
                          model: {
                            value: _vm.harzForm.judgementResult,
                            callback: function($$v) {
                              _vm.$set(_vm.harzForm, "judgementResult", $$v)
                            },
                            expression: "harzForm.judgementResult"
                          }
                        },
                        [
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 1 } },
                            [_vm._v("合格")]
                          ),
                          _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 0 } },
                            [_vm._v("超标")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "departmentDialog",
          staticClass: "edit-dialog",
          attrs: {
            pagination: false,
            title: _vm.ifEdit ? "编辑部门" : "添加部门",
            "if-show-close": true
          },
          on: { determine: _vm.addForms, cancel: _vm.close, close: _vm.close }
        },
        [
          _c(
            "el-form",
            {
              ref: "msForm",
              staticClass: "zwx-form ss",
              staticStyle: { "padding-left": "30px", "padding-top": "20px" },
              attrs: {
                model: _vm.msForm,
                "label-position": "right",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "simp",
                  staticStyle: { "margin-bottom": "25px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "车间部门：",
                    prop: "workplaceName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input width90",
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.msForm.workplaceName,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "workplaceName", $$v)
                      },
                      expression: "msForm.workplaceName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "continuation",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.addForms(true)
                    }
                  }
                },
                [_vm._v("连续添加")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "postDialog",
          staticClass: "edit-dialog",
          attrs: {
            pagination: false,
            title: "添加岗位",
            "if-show-close": true
          },
          on: {
            determine: _vm.addPostForm,
            cancel: _vm.closePost,
            close: _vm.closePost
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              staticClass: "zwx-form ss",
              staticStyle: { "padding-left": "30px", "padding-top": "20px" },
              attrs: {
                model: _vm.postForm,
                "label-position": "right",
                rules: _vm.postRules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "20px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "车间部门：",
                    prop: "workplaceName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input width90",
                    attrs: { disabled: "", clearable: "" },
                    model: {
                      value: _vm.postForm.workplaceName,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "workplaceName", $$v)
                      },
                      expression: "postForm.workplaceName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "simp",
                  staticStyle: { "margin-bottom": "20px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "岗位工种：",
                    prop: "jobName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input width90",
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.postForm.jobName,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "jobName", $$v)
                      },
                      expression: "postForm.jobName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "simp",
                  staticStyle: { "margin-bottom": "20px !important" },
                  attrs: {
                    "label-width": "110px",
                    label: "人数：",
                    prop: "staffNumber"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input width90",
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    on: { input: _vm.inptJy },
                    model: {
                      value: _vm.postForm.staffNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "staffNumber", $$v)
                      },
                      expression: "postForm.staffNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: {
                    "padding-bottom": "5px",
                    "margin-bottom": "20px !important"
                  },
                  attrs: {
                    "label-width": "110px",
                    label: "每班接触时长：",
                    prop: "exposureHd"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "234px !important" },
                    attrs: {
                      maxlength: "10",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    on: { input: _vm.inputExposureHd },
                    model: {
                      value: _vm.postForm.exposureHd,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "exposureHd", $$v)
                      },
                      expression: "postForm.exposureHd"
                    }
                  }),
                  _c("div", { staticClass: "unit" }, [_vm._v("h/d")])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "110px",
                    label: "每周接触天数：",
                    prop: "exposureDw"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "234px !important" },
                    attrs: {
                      maxlength: "10",
                      placeholder: "请输入",
                      clearable: ""
                    },
                    on: { input: _vm.inputExposureDw },
                    model: {
                      value: _vm.postForm.exposureDw,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "exposureDw", $$v)
                      },
                      expression: "postForm.exposureDw"
                    }
                  }),
                  _c("div", { staticClass: "unit" }, [_vm._v("d/w")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "continuation",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _c(
                "span",
                {
                  on: {
                    click: function($event) {
                      return _vm.addPostForm(true)
                    }
                  }
                },
                [_vm._v("连续添加")]
              )
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "harm" },
        [
          _c(
            "base-dialog",
            {
              ref: "harmDialog",
              staticClass: "edit-dialog",
              attrs: {
                pagination: false,
                title: "选择危害因素",
                "if-show-close": true
              },
              on: {
                determine: _vm.addHarmList,
                cancel: _vm.closeHarm,
                close: _vm.closeHarm
              }
            },
            [
              _c("div", { staticStyle: { display: "flex" } }, [
                _c("div", { staticClass: "hazardFactor-list2" }, [
                  _c(
                    "div",
                    { staticClass: "hazardFactor-search" },
                    [
                      _c(
                        "el-input",
                        {
                          ref: _vm.searchRef3,
                          staticClass: "zwx-input",
                          staticStyle: {
                            width: "270px !important",
                            "margin-right": "10px !important"
                          },
                          attrs: { placeholder: "搜索信息" },
                          model: {
                            value: _vm.searchInput3,
                            callback: function($$v) {
                              _vm.searchInput3 = $$v
                            },
                            expression: "searchInput3"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-search",
                            attrs: { slot: "suffix" },
                            slot: "suffix"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { height: "440px" } },
                    [
                      _c(
                        "zwx-scrollbar-y",
                        { staticStyle: { height: "100%" } },
                        [
                          _c("el-tree", {
                            ref: "tree3",
                            staticClass: "filter-tree",
                            attrs: {
                              "empty-text": "暂无数据",
                              data: _vm.gbzOhsSuspectedCodeNoTreeList,
                              props: _vm.defaultProps3,
                              "show-checkbox": "",
                              accordion: "",
                              "node-key": "codeNo",
                              "filter-node-method": _vm.filterNodeMethod3,
                              "expand-on-click-node": true,
                              "default-expanded-keys": _vm.checkRidArr3,
                              "check-strictly": _vm.isCheckStrictly3
                            },
                            on: { "check-change": _vm.handleCheckChange3 },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function({ node, data }) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "custom-tree-node",
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        width: "100%"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "light",
                                                disabled:
                                                  node.label.length <
                                                  (data.cas ? 6 : 20),
                                                content: data.hazardsName,
                                                placement: "top",
                                                "popper-class":
                                                  "contact-tooltip"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "name-hidden" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        hatext: data.cas
                                                      }
                                                    },
                                                    [_vm._v(_vm._s(node.label))]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(_vm._s(data.cas))
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "equipment-detail2" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          padding: "10px 0px 10px 12px",
                          "border-bottom": "1px solid #CDCDCC"
                        }
                      },
                      [
                        _vm._v(
                          "已选危害因素(" +
                            _vm._s(_vm.showOhsSuspectedCodeNoList.length) +
                            ")"
                        )
                      ]
                    ),
                    _c(
                      "el-table",
                      {
                        ref: "peResultDetailListTable",
                        staticClass: "zwx-table",
                        attrs: {
                          height: "461",
                          width: "100%",
                          "row-key": "itemCode",
                          border: "",
                          stripe: "",
                          data: _vm.showOhsSuspectedCodeNoList,
                          "show-header": false
                        }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            width: "330",
                            prop: "hazardsName",
                            label: "职业病名称",
                            "header-align": "center",
                            align: "left"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function({ row }) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.hazardsName + (row.cas || "")
                                      ) +
                                      " "
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            "header-align": "center",
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-button", {
                                    staticClass:
                                      "zwx-button zwx-button-text-26",
                                    attrs: {
                                      type: "text",
                                      icon: "el-icon-delete"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.delCheckResultDetail3(
                                          scope.$index
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: { position: "absolute", opacity: "0", display: "none" }
        },
        [
          _c("risk-hazard-factor-multiple", {
            key: _vm.hazardFactorKey,
            ref: "peHazardFactorMultipleEditRef",
            attrs: {
              innerWidth: 610,
              options: _vm.gbzOhsSuspectedCodeNoTreeList,
              dataPyxNameFiled: "hazardsName",
              dataNameFiled: "hazardsName",
              showNameFiled: "hazardName",
              dataCodeFiled: "codeNo",
              dataUpCodeFiled: "upCodeNo"
            },
            on: { sss: _vm.sss, change: _vm.sss }
          })
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              on: {
                click: function($event) {
                  return _vm.save(0)
                }
              }
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.save(1)
                }
              }
            },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "remind", staticStyle: { "margin-left": "12px" } },
      [
        _c("img", {
          attrs: {
            src: require("@/assets/images/workplace-detection/icon_info-circle-fill.png"),
            alt: ""
          }
        }),
        _c("span", [_vm._v(" 同一岗位或地点具有多个检测结果时，应填报最高值 ")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }