<template>
    <div>
      <base-dialog ref="projectDialog" :title="'检测项目'" @determine="addProject" class="tip-dialog" @cancel="closeProject">
        <el-form class="zwx-form ssk" ref="selectProject" style="padding-left:30px;padding-top:20px;" :model="selectProject" label-position="right">
          <el-form-item label-width="140px" label="检测项目：" prop="workplaceName" style="padding-bottom: 5px;">
            {{ selectProject.itemName }}
          </el-form-item>
          <div v-if="!selectProject.isNoise">
            <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdTwa)" label-width="140px" prop="valueTwa" style="padding-bottom: 5px;">
              <!-- <el-input class="zwx-input" v-model="postForm.jobName" placeholder="请输入" maxlength="20" clearable /> -->
              <template slot="label">
                C
                <sub>TWA</sub>
                ：
              </template>
              <el-input placeholder="请输入内容" v-model="selectProject.valueTwa" class="zwx-input" @input="$validate.number(selectProject, 'valueTwa', 4, 5)" auto-complete="off" :controls="false">
                <el-select popper-class="zwx-select-popper" v-model="selectProject.tagTwa" slot="prepend" placeholder="">
                  <el-option label="=" :value="1"></el-option>
                  <el-option label="<" :value="2"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdStel)" label-width="142px" prop="valueStel" style="padding-bottom: 5px;">
              <template slot="label">
                C
                <sub>STE</sub>
                ：
              </template>
              <el-input placeholder="请输入内容" v-model="selectProject.valueStel" class="zwx-input" @input="$validate.number(selectProject, 'valueStel', 4, 5)" auto-complete="off" :controls="false">
                <el-select v-model="selectProject.tagStel" slot="prepend" placeholder="">
                  <el-option label="=" :value="1"></el-option>
                  <el-option label="<" :value="2"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item v-if="!$zwxBase.verifyIsBlank(selectProject.itemStdMac)" label-width="142px" prop="valueMe" style="padding-bottom: 5px;">
              <template slot="label">
                C
                <sub>ME</sub>
                ：
              </template>
              <el-input placeholder="请输入内容" v-model="selectProject.valueMe" class="zwx-input" @input="$validate.number(selectProject, 'valueMe', 4, 5)" auto-complete="off" :controls="false">
                <el-select v-model="selectProject.tagMe" slot="prepend" placeholder="">
                  <el-option label="=" :value="1"></el-option>
                  <el-option label="<" :value="2"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
            <el-form-item v-if="!($zwxBase.verifyIsBlank(selectProject.itemStdTwa) || !$zwxBase.verifyIsBlank(selectProject.itemStdStel))" label-width="140px" prop="valuePe" style="padding-bottom: 5px;">
              <template slot="label">
                C
                <sub>PE</sub>
                ：
              </template>
              <el-input placeholder="请输入内容" v-model="selectProject.valuePe" class="zwx-input" @input="$validate.number(selectProject, 'valuePe', 4, 5)" auto-complete="off" :controls="false">
                <el-select v-model="selectProject.tagPe" slot="prepend" placeholder="">
                  <el-option label="=" :value="1"></el-option>
                  <el-option label="<" :value="2"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label-width="140px" label="噪声声效等级：" prop="noiseLex" style="padding-bottom: 5px;">
              <el-input placeholder="请输入内容" v-model="selectProject.noiseLex" class="zwx-input" @input="$validate.number(selectProject, 'noiseLex', 4, 2)" auto-complete="off" :controls="false"></el-input>
            </el-form-item>
          </div>
          <el-form-item label-width="140px" label="其他因素：" prop="otherValue" style="padding-bottom: 5px;">
            <el-input class="zwx-input" v-model="selectProject.otherValue" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label-width="140px" label="判定结果：" prop="judgementResult" style="padding-bottom: 5px;">
            <el-radio-group class="zwx-radio-group" v-model="selectProject.judgementResult">
              <el-radio class="zwx-radio" :label="1">合格</el-radio>
              <el-radio class="zwx-radio" :label="0">超标</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </base-dialog>
    </div>
  </template>
  <script>
  export default {
    name: 'projectDialog',
    data() {
      return {
        selectProject: {},
      }
    },
    methods: {
      show(selectProject) {
        this.selectProject = { ...selectProject }
        this.$refs.projectDialog.show(true)
      },
      addProject() {
        if (this.vaildHarm(this.selectProject)) {
          this.$system.notify('错误', '请至少填写一项结果值', 'error')
          return
        }
        this.$refs.projectDialog.show(false)
        this.selectProject.warningText = ''
        this.$emit('addProject', this.selectProject)
      },
      closeProject() {
        this.$refs.projectDialog.show(false)
      },
      vaildHarm(item) {
        if (item.isNoise) return this.$zwxBase.verifyIsBlank(item.noiseLex) && this.$zwxBase.verifyIsBlank(item.otherValue)
        return this.$zwxBase.verifyIsBlank(item.valueTwa) && this.$zwxBase.verifyIsBlank(item.valueStel) && this.$zwxBase.verifyIsBlank(item.valueMe) && this.$zwxBase.verifyIsBlank(item.valuePe) && this.$zwxBase.verifyIsBlank(item.otherValue)
      },
    },
  }
  </script>
  <style lang="less" scoped>
  .ssk {
    /deep/ .el-form-item {
      display: block !important;
      .el-input__suffix {
        display: none;
      }
      // .el-input__inner {
      //   width: 200px !important;
      // }
      .zwx-input {
        width: 240px !important;
      }
    }
    /deep/ .el-input-group__prepend {
      // .el-select {
      //   width: 10px;
      // }
      .el-input__inner {
        max-width: 40px;
        padding: 0px 0px 0px 15px;
      }
    }
  }
  </style>
  