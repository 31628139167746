var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "base-dialog",
        {
          ref: "postDialog",
          staticClass: "tip-dialog",
          attrs: { title: "添加岗位环节" },
          on: { determine: _vm.addPostForm, cancel: _vm.closePost }
        },
        [
          _c(
            "el-form",
            {
              ref: "postForm",
              staticClass: "zwx-form ss",
              staticStyle: { "padding-left": "30px", "padding-top": "20px" },
              attrs: {
                model: _vm.postForm,
                "label-position": "right",
                rules: _vm.postRules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "140px",
                    label: "部门车间：",
                    prop: "workplaceName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "50"
                    },
                    model: {
                      value: _vm.postForm.workplaceName,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "workplaceName", $$v)
                      },
                      expression: "postForm.workplaceName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "140px",
                    label: "检测岗位：",
                    prop: "jobName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      placeholder: "请输入",
                      maxlength: "50",
                      clearable: ""
                    },
                    model: {
                      value: _vm.postForm.jobName,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "jobName", $$v)
                      },
                      expression: "postForm.jobName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "142px",
                    label: "作业人数：",
                    prop: "staffNumber"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "5"
                    },
                    on: { input: _vm.inputStaffNumber },
                    model: {
                      value: _vm.postForm.staffNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "staffNumber", $$v)
                      },
                      expression: "postForm.staffNumber"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "142px",
                    label: "每班接触时长：",
                    prop: "exposureHd"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: { input: _vm.inputExposureHd },
                    model: {
                      value: _vm.postForm.exposureHd,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "exposureHd", $$v)
                      },
                      expression: "postForm.exposureHd"
                    }
                  }),
                  _c("div", { staticClass: "unit" }, [_vm._v("h/d")])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "padding-bottom": "5px" },
                  attrs: {
                    "label-width": "142px",
                    label: "每周接触天数：",
                    prop: "exposureDw"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: { input: _vm.inputExposureDw },
                    model: {
                      value: _vm.postForm.exposureDw,
                      callback: function($$v) {
                        _vm.$set(_vm.postForm, "exposureDw", $$v)
                      },
                      expression: "postForm.exposureDw"
                    }
                  }),
                  _c("div", { staticClass: "unit" }, [_vm._v("d/w")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }