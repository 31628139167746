<template>
  <el-dialog class="zwx-dialog" title="详情" :visible.sync="visible" @close="close()" :close-on-click-modal="false" :destroy-on-close="true">
    <div class="content">
      <el-form class="ms-form" key="msForm" ref="msForm" :model="msForm" label-position="right" label-width="150px" @submit.native.prevent>
        <el-form-item label="应用场景：" prop="appScenario">
          <span>{{ { 0: '通用商户', 1: '个性商户' }[msForm.appScenario] || '-' }}</span>
        </el-form-item>
        <el-form-item label="开票类型：" prop="businessType">
          <span>{{ { 1: '用友开票' }[msForm.businessType] || '-' }}</span>
        </el-form-item>
        <el-form-item label="配置名称：" prop="invoiceDescribe">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceDescribe" style="width: 100% !important" placeholder="请输入" clearable maxlength="200" /> -->
          <span>{{ msForm.invoiceDescribe || '-' }}</span>
        </el-form-item>
        <el-form-item label="环境地址：" prop="invoiceEnv">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceEnv" style="width: 100% !important" placeholder="请输入" clearable maxlength="200" /> -->
          <span>{{ msForm.invoiceEnv || '-' }}</span>
        </el-form-item>
        <el-form-item label="开票申请API地址：" prop="invoiceApiUrl">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceApiUrl" style="width: 100% !important" placeholder="请输入" clearable maxlength="200" /> -->
          <span>{{ msForm.invoiceApiUrl || '-' }}</span>
        </el-form-item>
        <el-form-item label="APPKEY：" prop="invoiceAppKey">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceAppKey" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" /> -->
          <span>{{ msForm.invoiceAppKey || '-' }}</span>
        </el-form-item>
        <el-form-item label="APP密钥：" prop="invoiceAppSecret">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceAppSecret" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" /> -->
          <span>{{ msForm.invoiceAppSecret || '-' }}</span>
        </el-form-item>
        <el-form-item label="发票保存地址：" prop="invoicePdfPath">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoicePdfPath" style="width: 100% !important" placeholder="请输入" clearable maxlength="200" /> -->
          <span>{{ msForm.invoicePdfPath || '-' }}</span>
        </el-form-item>
        <el-form-item label="纳税人识别号：" prop="invoiceSellerCreditCode">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceSellerCreditCode" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" /> -->
          <span>{{ msForm.invoiceSellerCreditCode || '-' }}</span>
        </el-form-item>
        <el-form-item label="商品税率：" prop="invoiceTaxRate">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceTaxRate" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" /> -->
          <span>{{ msForm.invoiceTaxRate || '-' }}</span>
        </el-form-item>
        <el-form-item label="是否无需审核：" prop="invoiceAutoAudit">
          <span>{{ msForm.invoiceAutoAudit ? '直接开票' : '需要审核' }}</span>
        </el-form-item>
        <el-form-item label="电子税务局用户名：" prop="invoiceAllelcUsername">
          <!-- <el-input class="zwx-input" v-model.trim="msForm.invoiceAllelcUsername" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" /> -->
          <span>{{ msForm.invoiceAllelcUsername || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
const initForm = () => {
  return {
    uuid: undefined,
    appScenario: 0,
    businessType: undefined,
    invoiceDescribe: '',
    invoiceEnv: '',
    invoiceApiUrl: '',
    invoiceAppKey: '',
    invoiceAppSecret: '',
    invoicePdfPath: '',
    invoiceSellerCreditCode: '',
    invoiceTaxRate: '',
    invoiceAutoAudit: true,
    invoiceAllelcUsername: '',
    invoiceAllelcPassword: '',
  }
}

export default {
  name: 'MerchantCreateDialog',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      rules: {
        appScenario: [{ required: true, message: '请选择应用场景', trigger: ['change', 'blur'] }],
        businessType: [{ required: true, message: '请选择开票类型', trigger: ['change', 'blur'] }],
        invoiceDescribe: [{ required: true, message: '请选择配置名称', trigger: ['change', 'blur'] }],
        invoiceEnv: [{ required: true, message: '请输入环境地址', trigger: ['change', 'blur'] }],
        invoiceApiUrl: [{ required: true, message: '请输入开票申请API地址', trigger: ['change', 'blur'] }],
        invoiceAppKey: [{ required: true, message: '请输入APPKEY', trigger: ['change', 'blur'] }],
        invoiceAppSecret: [{ required: true, message: '请输入APP密钥', trigger: ['change', 'blur'] }],
        invoicePdfPath: [{ required: true, message: '请输入发票保存地址', trigger: ['change', 'blur'] }],
        invoiceSellerCreditCode: [{ required: true, message: '请输入纳税人识别号', trigger: ['change', 'blur'] }],
        invoiceTaxRate: [{ required: true, message: '请输入商品税率', trigger: ['change', 'blur'] }],
        invoiceAutoAudit: [{ required: true, message: '请选择是否无需审核', trigger: ['change', 'blur'] }],
        invoiceAllelcUsername: [{ required: true, message: '请输入电子税务局用户名', trigger: ['change', 'blur'] }],
        invoiceAllelcPassword: [{ required: true, message: '请输入电子税务局密码', trigger: ['change', 'blur'] }],
      },
      visible: false,
      msForm: initForm(),
    }
  },
  created() {},
  methods: {
    commit() {
      this.$refs.msForm.validate(valid => {
        if (!valid) return

        let data = {
          ...this.msForm,
          invoiceAppKey: this.$zwxSm.sm4JsEncrypt(this.msForm.invoiceAppKey),
          invoiceAppSecret: this.$zwxSm.sm4JsEncrypt(this.msForm.invoiceAppSecret),
          invoiceSellerCreditCode: this.$zwxSm.sm4JsEncrypt(this.msForm.invoiceSellerCreditCode),
          invoiceAllelcUsername: this.$zwxSm.sm4JsEncrypt(this.msForm.invoiceAllelcUsername),
          invoiceAllelcPassword: this.$zwxSm.sm4JsEncrypt(this.msForm.invoiceAllelcPassword),
        }
        this.$system.postJson(this.api + '/training/platform/addOrModifyTrainingAgencyInvoice-1', data, true, true, this.commitSuccess, this.error)
      })
    },
    commitSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.close()
        this.$emit('confirm')
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    // 关闭弹框
    close() {
      this.visible = false
      this.msForm = initForm()
      this.$refs.msForm.resetFields()
    },
    // 打开弹框
    setShow(row) {
      if (row) {
        this.msForm = {
          ...initForm(),
          ...row,
          invoiceAppKey: this.$zwxSm.sm4JsDecrypt(row.invoiceAppKey) || '',
          invoiceAppSecret: this.$zwxSm.sm4JsDecrypt(row.invoiceAppSecret) || '',
          invoiceSellerCreditCode: this.$zwxSm.sm4JsDecrypt(row.invoiceSellerCreditCode) || '',
          invoiceAllelcUsername: this.$zwxSm.sm4JsDecrypt(row.invoiceAllelcUsername) || '',
          invoiceAllelcPassword: this.$zwxSm.sm4JsDecrypt(row.invoiceAllelcPassword) || '',
        }
      }
      this.visible = true
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 600px !important;
  min-width: 600px !important;
  .ms-dialog-footer-specical {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
/deep/ .ms-form {
  .el-form-item {
    display: block !important;
  }
  .zwx-select {
    width: 100%;
    .el-input__inner {
      width: 100% !important;
    }
  }
}
.content {
  padding: 50px 48px 40px 48px;
  box-sizing: border-box;
}
</style>
