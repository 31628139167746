<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="self-adapting-condition">
        <el-form-item label="开票类型：">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msForm.businessType" :multiple="false" collapse-tags placeholder="请选择" clearable>
            <el-option label="用友开票" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用场景：">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msForm.appScenario" :multiple="false" collapse-tags placeholder="请选择" clearable>
            <el-option label="通用开票" :value="0"></el-option>
            <el-option label="个性开票" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnableArr">
            <el-checkbox class="zwx-checkbox" :label="true">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="false">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryShelveGoodsList(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="openDialog()">
        添加
      </el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="uuid" :data="msForm.tableList" border stripe tooltip-effect="light">
      <el-table-column prop="appScenario" label="应用场景" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ { 0: '通用开票', 1: '个性开票' }[scope.row.appScenario] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="businessType" label="开票类型" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ { 1: '用友开票' }[scope.row.businessType] || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="invoiceDescribe" label="配置名称" min-width="300" header-align="center" align="left">
        <template slot-scope="scope">
          <span>{{ scope.row.invoiceDescribe || '-' }}</span>
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="price" label="原价(元)" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="salePrice" label="售价(元)" width="120" header-align="center" align="center" show-overflow-tooltip></el-table-column>
      -->
      <el-table-column prop="useUnitNum" label="使用单位数" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.useUnitNum || '-' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <!--
      <el-table-column prop="merchandisngDate" label="上架日期" width="180" header-align="center" align="center" :formatter="dateFormatter">
        <template slot-scope="scope">
          <span>{{ scope.row.merchandisngDate || '-' }}</span>
        </template>
      </el-table-column>
      -->
      <el-table-column fixed="right" label="操作" min-width="300" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable == 0" @click="openDialog(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-else @click="viewDetail(scope.row)">查看</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.ifEnable == 0" @click="changeState(scope.row.uuid, 1)">启用</el-button>
          <el-button class="zwx-button zwx-button-text-26 el-button--danger" type="text" v-if="scope.row.ifEnable == 1" @click="changeState(scope.row.uuid, 0)">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26 el-button--danger" type="text" v-if="scope.row.ifEnable == 0" @click="deleteGoods(scope.row.uuid)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="msForm.currentPage" :pageSize="msForm.pageSize" :total="msForm.total" @currentChange="queryShelveGoodsList" />

    <InvoiceCreateDialog ref="invoiceCreateDialog" @confirm="add" />
    <InvoiceDetailDialog ref="invoiceDetailDialog" @loading="loading" />
  </div>
</template>

<script>
import InvoiceCreateDialog from './InvoiceCreateDialog.vue'
import InvoiceDetailDialog from './InvoiceDetailDialog.vue'

export default {
  name: 'InvoiceInfo',
  components: { InvoiceCreateDialog, InvoiceDetailDialog },
  data() {
    return {
      api: this.$store.state.api,
      msForm: {
        businessType: undefined,
        appScenario: undefined,
        ifEnableArr: [true],
        currentPage: 1,
        tableList: [],
        pageSize: 18,
        total: 0,
      },
      // goodsTypeSimCodeList: [],
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryShelveGoodsList(1)
  },
  mounted() {
    /**
     * 
    this.$simpleCodeTools.getSimpleCodeListByCodeTypeCodes('4019', data => {
      this.goodsTypeSimCodeList = data['4019']
      this.$nextTick(() => {
        this.queryShelveGoodsList(1)
      })
    })
     */
    this.queryShelveGoodsList(1)
  },
  methods: {
    loading(loading) {
      this.$emit('loading', loading)
    },
    dateFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    queryShelveGoodsList(currentPage) {
      this.msForm.currentPage = currentPage
      let data = {
        currentPage,
        businessType: this.msForm.businessType,
        appScenario: this.msForm.appScenario,
        ifEnableArr: this.msForm.ifEnableArr,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/training/platform/getTrainingAgencyInvoiceList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.msForm.tableList = data.resultList
            this.msForm.pageSize = data.pageSize
            this.msForm.total = data.resultCount
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    openDialog(row) {
      this.$refs.invoiceCreateDialog.setShow(row)
    },
    viewDetail(row) {
      this.$refs.invoiceDetailDialog.setShow(row)
    },
    add() {
      this.queryShelveGoodsList(1)
    },
    edit() {
      this.queryShelveGoodsList(1)
    },
    changeState(uuid, ifEnable) {
      let tips = ifEnable === 0 ? '停用' : '启用'
      let url = '/training/platform/updateTrainingAgencyInvoiceState-1'
      this.$system.msgbox('', '提示', '是否确定' + tips + '？', '确定', '取消', () => {
        this.$emit('loading', true)
        let data = {
          uuid: uuid,
          ifEnable,
        }
        this.$system.post(
          this.api + url,
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', tips + '成功', 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
    deleteGoods(uuid) {
      this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
        this.$emit('loading', true)
        let data = {
          uuid: uuid,
        }
        // eslint-disable-next-line no-debugger
        this.$system.post(
          this.api + '/training/platform/deleteTrainingAgencyInvoice-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryShelveGoodsList(1)
            } else {
              this.$system.notify('错误', data.mess, 'error')
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
.zwx-select {
  /deep/ .el-input__inner {
    width: 220px !important;
  }
}
</style>
